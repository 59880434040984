import React from "react";
import Splash from "./Splash";
import TransformImage from "./lib/TransformImage";

const Materials = () => {
  return (
    <section id="materials-section">
      <Splash
        image={"material-banner"}
        text={"The Best Products From The Best Materials"}
      />
      <div className="materials-info">
        <article className="materials-about">
          <h1 className="main-title border-line">
            We only use the highest quality material
          </h1>
          <p className="main-info">
            RS Godwin maintains a position high in the market by manufacturing
            products from the highest quality materials. We believe that dump
            bodies are only as good as the materials they're made from. The use
            of quality materials allows for higher precision engineering,
            superior build quality, superior durability, and longer life of the
            product.
          </p>
          <p className="main-info">
            Unfortunately, many dump body customers are unfamiliar with the
            specific materials used to manufacture their products, and the
            importance of using certified material. As always, mill
            certification documentation is available upon request.
          </p>
        </article>
        <div className="materials-photo">
          <TransformImage
            imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/materials/steel-inverntory-background_hqgro4.jpg"
            imageSizes={[572, 710, 1044, 1400]}
            maxSize="(max-width: 1400px) 100vw, 1400px"
            alt="Steel inventory background"
          />
        </div>
      </div>
    </section>
  );
};

export default Materials;
