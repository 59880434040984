import React from "react";
import { Link } from "react-router-dom";

const Button = ({ text, cta, link, dist, pdf }) => {
  if (link)
    return (
      <div className="button-container">
        <Link className={`${cta ? `${cta}` : "button"}`} to={link}>
          {text}
        </Link>
      </div>
    );
  else if (dist)
    return (
      <div className="button-container">
        <a
          className={`${cta ? `${cta}` : "button"}`}
          target="_blank"
          rel="noopener noreferrer"
          href="http://godwingrouponline.com/search/"
        >
          {text}
        </a>
      </div>
    );
  else if (pdf) {
    return (
      <div className="button-container">
        <a
          className={`${cta ? `${cta}` : "button"}`}
          target="_blank"
          rel="noopener noreferrer"
          href={pdf}
        >
          {text}
        </a>
      </div>
    );
  } else {
    return (
      <div className="button-container">
        <button
          className={`${cta ? `${cta}` : "button"}`}
          onClick={() => {
            document
              .getElementById("testimonials")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          {text}
        </button>
      </div>
    );
  }
};

export default Button;
