import React from "react";
import TransformImage from "./lib/TransformImage";
import Link from "./backend";

// Components
import Button from "./Button";

const AboutUs = () => {
  return (
    <section id="about-section">
      <article className="default-grid">
        <picture className="about-image">
          <TransformImage
            imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/about/quarry_jvilwt.jpg"
            imageSizes={[525, 600, 798, 1019, 1320]}
            maxSize="(max-width: 1320px) 100vw, 1320px"
            alt="Coal quarry"
          />
        </picture>

        <div className="about-info">
          <h1 className="main-title">Over 50 Years of Experience</h1>
          <p className="main-info">
            In 1968 R/S-Godwin began as a local truck job shop supporting our
            local Eastern Kentucky coal industry. Now we're a premier dump body
            manufacturer with products distributed throughout the world. The
            rugged mountains and mines of Appalachia were the ideal proving
            grounds for superior dump body product development and evolution.
            Whether large, small, steel, or aluminum, R/S-Godwin specializes in
            custom built dump bodies designed specifically for your needs. Don't
            take our word for it. Read what our customers have to say.
          </p>
          <Button
            text={"Learn more"}
            cta={"testimonial-button"}
            scrollTo={"#testimonials"}
          />
        </div>
      </article>
      <article className="default-grid">
        <div className="about-info">
          <h1 className="main-title">Unmatched Value and Innovation</h1>
          <p className="main-info">
            Absolutely no other dump body manufacturer in the industry provides
            customers with better value than R/S-Godwin. We challenge
            prospective customers to do their research and compare 'apples to
            apples'. Our dump bodies were developed with the operator AND owner
            in mind.
          </p>
          <p className="main-info">
            We lead the way with original innovations that leave 'others'
            scrambling to keep up. Some advancements like the patented
            telescopic hoist stabilizer, and RoadRunner remote dump system for
            example are only available on Godwin bodies. Other standard value
            added features like zinc powder coat primer simply can't be matched.
            Learn more about the materials we use.
          </p>
          <Button
            text={"Learn more"}
            cta={"cta-button"}
            link={Link.Pages.MaterialPageRoute}
          />
        </div>
        <picture className="about-image">
          <TransformImage
            imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_6_vw3chd.jpg"
            imageSizes={[467, 572, 797, 1024]}
            maxSize="(max-width: 1024px) 100vw, 1024px"
            alt="RS Godwin CS steel body"
          />
        </picture>
      </article>
    </section>
  );
};

export default AboutUs;
