const PDF = {
  AuSeries: "/pdf/au-series.pdf",
  AgSeries: "/pdf/ag-series.pdf",
  HybridSeries: "/pdf/hybrid-series.pdf",
  CSeries: "/pdf/c-series.pdf",
  CsSeries: "/pdf/cs-series.pdf",
  ElSeries: "/pdf/el-series.pdf",
  GSeries: "/pdf/g-series.pdf",
  WgSeries: "/pdf/wg-series.pdf",
};

const Products = {
  CsSeriesRoute: "/products/steel-bodies/cs-series",
  CSeriesRoute: "/products/steel-bodies/c-series",
  GSeriesRoute: "/products/steel-bodies/g-series",
  ElSeriesRoute: "/products/steel-bodies/el-series",
  ScSeriesRoute: "/products/steel-bodies/sc-series",
  AcSeriesRoute: "/products/aluminum-bodies/ac-series",
  AgSeriesRoute: "/products/aluminum-bodies/ag-series",
  AuSeriesRoute: "/products/aluminum-bodies/au-series",
  DoubleWallSeriesRoute: "/products/hybrid-bodies/doublewall-series",
  StandardSeriesRoute: "/products/hybrid-bodies/standard-series",
};

const Showcase = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_et9lh5.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_2_ufxiii.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_3_eyitbt.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_4_rqojeb.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 5,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_5_vx4p2c.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 6,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_6_vw3chd.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 7,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_7_gqjnp2.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 8,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_8_nclse6.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 9,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/SCS_9_fj7sgv.jpg",
    Title: "CS/SCS Series",
    Detail: "Steel body",
    Link: Products.CsSeriesRoute,
  },
  {
    key: 10,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/AG_kqtrnt.jpg",
    Title: "AG Series",
    Detail: "Aluminum body",
    Link: Products.AgSeriesRoute,
  },
  {
    key: 11,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/EL_zlulyi.jpg",
    Title: "EL Series",
    Detail: "Steel body",
    Link: Products.ElSeriesRoute,
  },
  {
    key: 12,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/doublewalled_qsrtaw.jpg",
    Title: "Double Walled Series",
    Detail: "Hybrid body",
    Link: Products.DoubleWallSeriesRoute,
  },
  {
    key: 13,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/doublewalled2_uq1ycp.jpg",
    Title: "Double Walled Series",
    Detail: "Hybrid body",
    Link: Products.DoubleWallSeriesRoute,
  },
  {
    key: 14,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/showcase/doublewalled3_gg9aql.jpg",
    Title: "Double Walled Series",
    Detail: "Hybrid body",
    Link: Products.DoubleWallSeriesRoute,
  },
  {
    key: 15,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/hybrid/standard-hybrid_su7gw9.jpg",
    Title: "Standard Walled Series",
    Detail: "Hybrid body",
    Link: Products.StandardSeriesRoute,
  },
];

const SteelSeries = [
  {
    name: "CS / SCS Series",
    photo: "rs-godwin/nav/steel/cs-series_maiyfe.jpg",
    link: Products.CsSeriesRoute,
  },
  {
    name: "C Series",
    photo: "rs-godwin/nav/steel/c-series_hdqw1w.jpg",
    link: Products.CSeriesRoute,
  },
  {
    name: "El Series",
    photo: "rs-godwin/nav/steel/gray_EL_2_dwhcsl.jpg",
    link: Products.ElSeriesRoute,
  },
  {
    name: "G Series",
    photo: "rs-godwin/nav/steel/g-series_kyhaow.jpg",
    link: Products.GSeriesRoute,
  },
  {
    name: "SC Series",
    photo: "rs-godwin/nav/steel/sc-series_d4fgi9.jpg",
    link: Products.ScSeriesRoute,
  },
];

const AluminumSeries = [
  {
    name: "AC Series",
    photo: "rs-godwin/nav/aluminum/ac-series_mbxtpa.jpg",
    link: Products.AcSeriesRoute,
  },
  {
    name: "AG Series",
    photo: "rs-godwin/nav/aluminum/ag-series_vueb2e.jpg",
    link: Products.AgSeriesRoute,
  },
  {
    name: "AU Series",
    photo: "rs-godwin/nav/aluminum/au-series_lnqmr9.jpg",
    link: Products.AuSeriesRoute,
  },
];

const HybridSeries = [
  {
    name: "Double Walled",
    photo: "rs-godwin/nav/hybrid/doublewall-hybrid_figh8e.jpg",
    link: Products.DoubleWallSeriesRoute,
  },
  {
    name: "Standard Hybrid",
    photo: "rs-godwin/products/hybrid/standard/3_qssoco.jpg",
    link: Products.StandardSeriesRoute,
  },
];

const Pages = {
  HomePageRoute: "/",
  CompanyPageRoute: "/our-company",
  PaintProcessRoute: "/paint-process",
  ContactPageRoute: "/contact-us",
  MaterialPageRoute: "/materials",
  LiteraturePageRoute: "/literature",
};

const SteelPhotos = {
  CSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/steel/c-series_hdqw1w.jpg",
  CsSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/steel/cs-series_maiyfe.jpg",
  ElSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/steel/gray_EL_2_dwhcsl.jpg",
  GSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/steel/g-series_kyhaow.jpg",
  ScSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/steel/sc-series_d4fgi9.jpg",
};

const AlumPhotos = {
  AcSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/aluminum/ac-series_mbxtpa.jpg",
  AgSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/aluminum/ag-series_vueb2e.jpg",
  AuSeriesMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/aluminum/au-series_lnqmr9.jpg",
};

const HybridPhotos = {
  StandardMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/hybrid/standard/3_qssoco.jpg",
  DoubleWallMain:
    "https://res.cloudinary.com/clee/image/upload/rs-godwin/nav/hybrid/doublewall-hybrid_figh8e.jpg",
};

const transformations = {
  dpr: "auto",
  width: "auto",
  crop: "scale",
  fetchFormat: "auto",
};

const navTransformations = {
  dpr: "auto",
  width: "auto",
  crop: "scale",
  fetchFormat: "auto",
};

export default {
  PDF,
  Showcase,
  Products,
  Pages,
  SteelPhotos,
  AlumPhotos,
  HybridPhotos,
  transformations,
  navTransformations,
  SteelSeries,
  AluminumSeries,
  HybridSeries,
};
