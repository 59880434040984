import React from "react";
import { Link } from "react-router-dom";
import Routes from "./backend";

const Footer = () => {
  return (
    <footer id="footer-section">
      <div className="footer-title">
        <Link to="/">
          <img
            src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_400/v1/rs-godwin/footer/logo-inversed_i80bsr.png"
            alt="RS Godwin logo"
            className="footer-logo"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="default-grid">
        <div className="footer-box">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://goo.gl/maps/ewVn42Y7QvnYmXZ56"
          >
            <span>
              <i className="fas fa-map-marker-alt"></i>
            </span>
            5168 US-23, Ivel, KY 41642
          </a>
          <a href="tel:800-826-7413">
            <span>
              <i className="fas fa-mobile-alt"></i>
            </span>
            (800) 826-7413
          </a>
          <div className="footer-extras">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://godwingrouponline.com/careers.html"
            >
              Careers
            </a>
            <Link to={Routes.Pages.ContactPageRoute}>Contact Us</Link>
          </div>
        </div>

        <div>
          <div className="footer-extras">
            <p style={{ marginTop: "0" }}>Keep in Touch</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://godwingrouponline.com/"
            >
              <img
                src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_150/rs-godwin/icons/logo_hurz14.png"
                alt="Godwin Group logo"
                className="footer-logo"
                loading="lazy"
              />
            </a>
          </div>

          <div className="footer-social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/godwinmfg/"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/the-godwin-group/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <p>
        <small>
          © 2020 R/S Godwin. All rights reserved.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.privacypolicytemplate.net/live.php?token=lfRU8fdC1XieHtlAEMYaD3l6RL1tpFBO"
          >
            Privacy Policy & Terms of Use
          </a>
        </small>
      </p>
    </footer>
  );
};
export default Footer;
