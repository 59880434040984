import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/au-series/1_moly4c.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/au-series/2_vxp6hg.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/au-series/3_yhxuok.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/au-series/4_juwmav.jpg"
  }
];

const AuSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Aluminum Dump Body</h2>
        <h1 className="product-maintitle">AU Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.AlumPhotos.AuSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="AU Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The R/S AU series is a versatile aluminum dump body designed to
            handle the work load for the 1-ton and super duty truck chassis. The
            smallest of the R/S aluminum body line, the AU is well developed and
            and highly customizable with popular options like fold down sides.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.AuSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li> Lengths: Available in inside lengths from 8’6” through 11’6”</li>
          <li>Inside Width: 86” side to side</li>
          <li>Side Heights: 12” through 18”</li>
          <li>Tailgate Heights: 18” through 24”</li>
          <li>
            Understructure: Stacked design utilizing 6” Aluminum Rubber
            Cushioned Longsills gusseted to 3” Aluminum Crossmembers. Full width
            rear bolster
          </li>
          <li>
            Floor: 3/16” Aluminum (5454 H34), with 2” radius floor to side,
            (except for Drop Side)
          </li>
          <li>Bulkhead: 3/16” Aluminum, (5454 H34)</li>
          <li>
            Sides: 3/16” Aluminum (5454 H34) Front post, 2 intermediate side
            post, Full depth rear post with a formed top rail. 6" front and rear
            board extension pockets
          </li>
          <li>
            Tailgate: 3/16” Aluminum (5454 H34), 3 Panel , Double Acting top
            tailgate hardware with long chains and two sets of banjo plates.
            Manual tailgate release
          </li>
          <li>
            Lights: FMVSS108 with oval stop/tail/turn lights recessed in rear
            post. All lighting is LED recessed, shock mounted, complete with
            factory wiring
          </li>
          <li>Hoist: Hoists sized for body lengths</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Cabshield (various lengths)</li>
            <li>Aluminum Boards</li>
            <li>Wood Boards</li>
            <li>Various Tarping Systems</li>
            <li>Window in Bulkhead</li>
            <li>Various Hoist Configurations</li>
            <li>Stainless Steel Drop Pin top Tailgate Hardware</li>
            <li>Bolt On Spreader Apron</li>
          </ul>
          <ul>
            <li>Fold Down Sides</li>
            <li>Shovel Holder</li>
            <li>1/4” Aluminum Floor</li>
            <li>Additional Lighting</li>
            <li>Various Tool Boxes</li>
            <li>Asphalt (stoker) Door</li>
            <li>Smooth Body Option</li>
          </ul>
        </div>
      </div>
      <ProductCta />;
    </section>
  );
};

export default AuSeries;
