import React from "react";

const PDF = {
  AuSeries: "/pdf/au-series.pdf",
  AgSeries: "/pdf/ag-series.pdf",
  HybridSeries: "/pdf/hybrid-series.pdf",
  CSeries: "/pdf/c-series.pdf",
  CsSeries: "/pdf/cs-series.pdf",
  ElSeries: "/pdf/el-series.pdf",
  GSeries: "/pdf/g-series.pdf",
  WgSeries: "/pdf/wg-series.pdf",
};

const LiteraturePage = () => {
  return (
    <section id="literature-section">
      <div className="literature-intro">
        <h1>Literature page</h1>
        <p>Click to view literatue about the product</p>
      </div>
      <div className="literature-table">
        <table>
          <thead>
            <tr>
              <th>Steel Bodies</th>
              <th>Aluminum Bodies</th>
              <th>Hybrid Bodies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a target="_blank" rel="noopener noreferrer" href={PDF.CSeries}>
                  C Series
                </a>
              </td>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PDF.AgSeries}
                >
                  AG Series
                </a>
              </td>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PDF.HybridSeries}
                >
                  Double Walled / Standard
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PDF.CsSeries}
                >
                  S(CS) Series
                </a>
              </td>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PDF.AuSeries}
                >
                  AU Series
                </a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PDF.ElSeries}
                >
                  El Series
                </a>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <a target="_blank" rel="noopener noreferrer" href={PDF.GSeries}>
                  G Series
                </a>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default LiteraturePage;
