import React from "react";
import TransformImage from "./lib/TransformImage";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

const CallToAction = () => {
  const { register, errors, handleSubmit, reset } = useForm();
  const onSubmit = ({ email_address, number }) => {
    if (number) {
      return;
    }
    const body = {
      email_address: email_address,
      message: "Sign up for newsletter",
    };
    axios({
      method: "POST",
      url: "https://formspree.io/mdowyngn",
      data: body,
    })
      .then(() => {
        Swal.fire({
          title: "Successfully signed up.",
          icon: "success",
          confirmButtonText: "Thank you",
        });
        reset({
          email_address: "",
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <section className="call-to-action">
      <div className="cta-container">
        <TransformImage
          imageUrl="https://res.cloudinary.com/clee/image/upload/v1/rs-godwin/banners/banner_bwdl86.jpg"
          imageSizes={[583, 852, 1077, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          alt="RS Godwin welding action"
          className="cta-background"
        />
        <div className="cta-info">
          <h1>Stay Connected</h1>
          <p style={{ textAlign: "center" }}>
            Want to stay up-to-date on what's happening at R/S Godwin? <br />
            <span
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              Join our mailing list to get the latest information.
            </span>
          </p>
          <form className="cta-form" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              {/* Honey Pot */}
              <input
                name="number"
                type="number"
                placeholder="Number"
                ref={register({ required: false })}
                style={{ transform: "translate(-500%, -500%)" }}
              />
              <input
                type="email"
                placeholder="Email Sign Up"
                id="cta-signup"
                name="email_address"
                required
                ref={register({ required: true })}
              />
              {errors.email_address?.type === "required" && (
                <span style={{ color: "red " }}>
                  You must enter a valid email.
                </span>
              )}
              <button type="submit" className="cta-button">
                Submit
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
