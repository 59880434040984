import React from "react";

// Components
import Video from "../Video";
import AboutUs from "../AboutUs";
import Showcase from "../Showcase";
import Testimonials from "../Testimonials";
import VideoModal from "../VideoModal";

const HomePage = () => {
  return (
    <main>
      <Video />
      <AboutUs />
      <VideoModal />
      <Showcase />
      <Testimonials />
    </main>
  );
};

export default HomePage;
