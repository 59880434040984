import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { viewportContext } from "./lib/viewportContext";

// Components
import Button from "./Button";

const NavBarLinks = ({
  product,
  resources,
  about,
  menu,
  setToggle,
  buttonLink,
  homeLink
}) => {
  const { width } = useContext(viewportContext);

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="logo">
          <Link to={homeLink}>
            <img
              src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_400/v1/rs-godwin/logo_bqhoqi.png"
              className="logo"
              loading="lazy"
              alt="RS Godwin logo"
            />
          </Link>
        </div>
        <div className="navbar-links">
          <button
            className={`navbar-link-item ${product ? "active" : ""}`}
            onClick={() =>
              setToggle({
                product: !product
              })
            }
          >
            Products
          </button>
          <button
            className={`navbar-link-item ${resources ? "active" : ""}`}
            onClick={() =>
              setToggle({
                resources: !resources
              })
            }
          >
            Resources
          </button>
          <button
            className={`navbar-link-item ${about ? "active" : ""}`}
            onClick={() =>
              setToggle({
                about: !about
              })
            }
          >
            About
          </button>
        </div>
        {width > 800 && (
          <div className="button-container">
            <Button
              text={`Contact Us`}
              link={buttonLink}
              cta={"contact-button"}
            />
          </div>
        )}
        <div
          className={`hamburger-container ${menu ? `transform` : ""}`}
          onClick={() =>
            setToggle({
              menu: !menu
            })
          }
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
    </nav>
  );
};

export default NavBarLinks;
