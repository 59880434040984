import React from "react";
import Splash from "./Splash";
import TransformImage from "./lib/TransformImage";

const Company = () => {
  return (
    <section id="company-section">
      <Splash image={"coal-banner"} text={"Our Story"} />
      <div className="company-info">
        <article>
          <h1 className="main-title border-line">R/S Godwin</h1>
          <p className="main-info">
            R/S-Godwin Truck Body Company began in 1968 as a small local job
            shop catering to the Eastern Kentucky coal industry with a marketing
            range of only a couple hundred miles. Building bodies for the coal
            industry could be very good business as long as coal was stable or
            growing. The main product focus was on highway use trucks, but would
            occasionally build equipment for off hwy use when local demand
            called for it.
          </p>
          <TransformImage
            imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/company/coal-truck_dn3wu7.jpg"
            imageSizes={[506, 617, 817]}
            maxSize="(max-width: 817px) 100vw, 817px"
            alt="Coal truck"
          />
          <p className="main-info">
            R/S was too closely tied to the volatile coal markets to continue
            stable growth. The company began building a wider range of steel
            dump bodies and trailers that were better suited for construction
            and general hauling use.
          </p>
          <p className="main-info">
            In 1977, R/S decided to diversify into other dump body markets.
            Aluminum dump bodies and trailers were added to the existing line
            steel products to satisfy weight conscious operators. The company
            grew rapidly, and In 1986 purchased Page Suspension Manufacturing
            from Dura Corporation to further expand the company's capabilities.
          </p>
          <p className="main-info">
            In 1998 R/S built and moved to a new 150,000 square foot location to
            better accommodate the company's growth and production needs. In
            2002 R/S was purchased by The Godwin Group out of North Carolina.
            The name was changed to R/S-Godwin Truck Body Company LLC. This move
            further solidified its presence in the truck equipment market. It
            became part of the most successful family owned truck equipment.
          </p>
          <p className="main-info">
            Since becoming part of the Godwin family, many changes have taken
            place at R/S. Product demand became so high that the decision was
            made to remove trailers for R/S's offerings to better focus on dump
            body production. The Page Suspension brand was subsequently sold to
            a competitive suspension company further increasing focus on body
            manufacturing capacity. Recently, the company expanded to 165,000
            square feet to accommodate a new state of the art powder coat paint
            and primer facility.
          </p>
        </article>
      </div>
      <Splash image={"company-banner"} />
      <div className="company-info">
        <article>
          <h1 className="main-title border-line">Family Owned & Operated</h1>
          <p className="main-info">
            With three generations currently working in our company and a fourth
            about to begin, the business is as much a part of our family as
            anyone. This is also beneficial to customers. We take great pride in
            our craft and are determined to provide the best customer service
            possible. Being family owned and operated means something. It means
            we eat, sleep, and breath what we do. Working for our business isn't
            just a job. It's a legacy. Our policy is to treat customers the way
            we expect to be treated.
          </p>
          <p className="main-info">
            Our goal is to develop and manufacture the most innovative and
            highest quality products available in the industry.
          </p>
          <TransformImage
            imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/company/godwinfamily_mj9iyo.jpg"
            imageSizes={[492, 600, 800]}
            maxSize="(max-width: 800px) 100vw, 800px"
            alt="Godwin Family"
          />
        </article>
      </div>
    </section>
  );
};

export default Company;
