import React from "react";

// Components
import Company from "../Company";

const CompanyPage = () => {
  return <Company />;
};

export default CompanyPage;
