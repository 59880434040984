import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import TransformImage from "./lib/TransformImage";

const ProductGallery = ({ photos }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }
  return (
    <section className="product-option-section">
      <div className="title">
        <h1 className="main-title border-line">Check Out The Options</h1>
      </div>
      <div className="product-option-gallery">
        {photos.map((el, i) => (
          <div
            className="product-option-photo"
            onClick={() => openLightboxOnSlide(i + 1)}
            key={i}
          >
            <TransformImage
              imageUrl={el.photo}
              imageSizes={[320, 480, 768, 1024, 1200, 1400]}
              maxSize="(max-width: 1400px) 100vw, 1400px"
              alt="CS Series body"
            />
          </div>
        ))}
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={photos.map((el) => el.photo)}
        slide={lightboxController.slide}
        captions={photos.map((el, i) => (
          <div key={i}>
            <p style={{ fontSize: "1.25rem" }}>{el.caption}</p>
          </div>
        ))}
      />
    </section>
  );
};

export default ProductGallery;
