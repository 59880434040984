import React, { useState } from "react";
import Splash from "../Splash";
import TransformImage from "../lib/TransformImage";

const PaintProcess = () => {
  const [collapsed, setCollapsed] = useState({
    paintProcess: true,
    labResults: true
  });
  return (
    <section id="paint-process-section">
      <article style={{ marginBottom: "5rem" }}>
        <h1 className="main-title border-line">Our Painting Process</h1>
        <p className="main-info">
          For optional color and cab match needs, R/S uses premium PPG liquid
          paints. When the powder coat paint system becomes operational, liquid
          paints will be applied over our powder coat zinc primer. This
          maximizes value and durability for a long lasting corrosion resistant
          finish that is unmatched. For now, all paint and primer is liquid.
        </p>
        <p className="main-info">
          Our liquid paint product of choice is PPG Delfleet Evolution. The
          Delfleet® Evolution system represents PPG’s latest advance in fleet
          coatings technology and has become the finish system of choice for
          commercial vehicle manufacturers and fleet maintenance operators who
          want the ultimate in performance and durability. Incorporating proven
          technology, total color solutions and best-in-class service, this
          premium, painter-friendly system delivers the color, gloss and
          durability demanded for harsh day-in, day-out work environments.
        </p>
      </article>
      <article>
        <h1 className="main-title border-line">
          Powder Coat Process{" - "}
          <span style={{ color: "red" }}>Available Soon</span>
        </h1>
        <p className="main-info">
          Godwin Manufacturing Company is one of only three dump body
          manufacturers in the industry that finishes their bodies in thermoset
          powder coat paint and primer. The other manufacturers are Godwin's
          sister companies Galion-Godwin Truck Body Company and
          Williamsen-Godwin.
        </p>
        <p className="main-info">
          Powder coat paint and thermoset powder coat zinc primer are both
          standard equipment on all Godwin carbon steel bodies. When you
          purchase a Godwin dump body, it leaves the facility with a finished
          coating which has been lab tested and certified by SAE 117-B
          accelerated salt spray test for no less than 4000 hours using 5% salt
          solution (ocean water is 3%). Industry standard paint and primer
          process don't even come close. Powder coating is also superior in
          abrasion resistances, chemical resistances and chip resistance. Not
          only is powder coating superior in quality, but it's also safer for
          the environment. Godwin's powder coat paint and primer process produce
          no VOC's, HAP's and generates no hazardous waste. A zinc primed and
          powder coated body is a cost effective alternative to stainless and
          aluminum bodies in environments where corrosion is a major concern.
          Learn more about Powder Coating and Godwin's paint process below.
        </p>
      </article>

      <article className="paint-process-card">
        <div className="paint-process-card__info">
          <h1 className="main-title">Illustrated Paint and Primer Process</h1>
          <div className={`${collapsed.paintProcess ? "is-collapsed" : ""}`}>
            <picture>
              <TransformImage
                imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/paint-process/paint_illustration_uk3127.jpg"
                imageSizes={[460, 527, 646, 820, 807]}
                maxSize="(max-width: 907px) 100vw, 907px"
                alt="Paint and primer process"
              />
            </picture>
          </div>
          <button
            onClick={() => {
              setCollapsed({
                paintProcess: !collapsed.paintProcess,
                labResults: collapsed.labResults
              });
            }}
          >
            {collapsed.paintProcess ? "Show More" : "Show Less"}
          </button>
        </div>
      </article>
      <article className="paint-process-card">
        <div className="paint-process-card__info">
          <h1 className="main-title">Lab Test Results</h1>
          <div className={`${collapsed.labResults ? "is-collapsed" : ""}`}>
            <picture className="about-image">
              <TransformImage
                imageUrl="https://res.cloudinary.com/clee/image/upload/rs-godwin/paint-process/paint_test_tuigt5.jpg"
                imageSizes={[461, 658, 845, 900]}
                maxSize="(max-width: 900px) 100vw, 900px"
                alt="Paint and primer process"
              />
            </picture>
          </div>
          <button
            onClick={() => {
              setCollapsed({
                paintProcess: collapsed.paintProcess,
                labResults: !collapsed.labResults
              });
            }}
          >
            {collapsed.labResults ? "Show More" : "Show Less"}
          </button>
        </div>
      </article>
    </section>
  );
};

const PaintProcessPage = () => {
  return (
    <>
      <Splash image={"paint-banner"} text={"Paint & Primer Process"} />
      <PaintProcess />
    </>
  );
};

export default PaintProcessPage;
