import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";
import ProductGallery from "../../ProductGallery";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/cs-series/1_w88lo7.jpg",
    caption: "Hello"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/cs-series/3_nhcmil.jpg",
    caption: "World"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/cs-series/2_f5c5xr.jpg",
    caption: "Testing"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/cs-series/4_d4ppwc.jpg",
    caption: "Captions"
  },
  {
    key: 5,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/cs-series/5_eqvwdl.jpg",
    caption: "Hello"
  }
];

const CsSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Steel Dump Body</h2>
        <h1 className="product-maintitle">CS / SCS Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.SteelPhotos.CsSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="CS Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The CS series steel body is designed to satisfy the needs of weight
            conscious haulers who rely on the durability of steel. Its
            engineering makes it significantly lighter yet maintains durability
            comparable or better than traditional steel dump bodies. A standard
            CS body is generously equipped, and there are numerous options
            available to create the perfect workhorse for your needs.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.CsSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>
            Various body lengths from 12' and side heights from 30" to 64" are
            standard
          </li>
          <li>12" side to floor radius for improved dumping</li>
          <li>Formed trapezoid longsills with rubber cushion</li>
          <li>
            96" wide one piece 3/16" HardOx floor with 12" rolled side to floor
            radius and 60" wide flat center for cleaner dumping
          </li>
          <li>Crossmemberless understructure</li>
          <li>3/16" material shedding formed box top rails</li>
          <li>10 gauge extra high tensile steel bulkhead</li>
          <li>
            Full length horizontal side brace each side with 10 gauge high
            strength sides and tailgate
          </li>
          <li>Recessed FMVSS108 L.E.D. lighting</li>
          <li>Signals in rear posts</li>
          <li>Front mount telescopic hoists sized for body lengths</li>
          <li>Air operated tailgate latching system</li>
          <li>PPG Delfleet Evolution and Delfleet Essential</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Vertical Side Bracing</li>
            <li>Smooth Side Design</li>
            <li>Wood, Steel or Aluminum Boards</li>
            <li>Tubing Top Rail</li>
            <li>Sloped Tailgate</li>
            <li>Asphalt Tapered Rear</li>
            <li>Stoker Doors</li>
            <li>Aluminum Tailgate</li>
            <li>Sealed Tailgate</li>
            <li>Wing Latches on Tailgate</li>
            <li>Air High Lift Tailgate</li>
            <li>Inverted and Non-inverted Trunnion Mount Cylinder</li>
          </ul>
          <ul>
            <li>Offset Bulkheads</li>
            <li>1/4” AR450 Steel</li>
            <li>Overlays for Sides, Deck & Bulkhead</li>
            <li>Aluminum Toolboxes</li>
            <li>Various Tarping System</li>
            <li>Aluminum Hydraulic Tanks</li>
            <li>Side Graphics</li>
            <li>Chrome Exhaust S-Pipe</li>
            <li>Inverted Angle Available for Top Rails</li>
            <li>Bolt On Weld On or Pipe Apron Options</li>
            <li>Aluminum Control Tower</li>
            <li>Additional Lighting</li>
          </ul>
        </div>
      </div>
      <ProductGallery photos={photos} />
      <ProductCta />
    </section>
  );
};

export default CsSeries;
