import React, { useState } from "react";
import Modal from "react-modal-video";

const options = [
  {
    thumbnail:
      "https://res.cloudinary.com/clee/image/upload/v1598475978/rs-godwin/thumbnails/stabilizer_cwtgpi.jpg",
    link: "ibDgkui-E-U"
  },
  {
    thumbnail:
      "https://res.cloudinary.com/clee/image/upload/v1598475979/rs-godwin/thumbnails/ecohoist_v5e0ex.jpg",
    link: "JphnFGuK0HU"
  },
  {
    thumbnail:
      "https://res.cloudinary.com/clee/image/upload/v1598475979/rs-godwin/thumbnails/roadrunner_ft2jdh.jpg",
    link: "JphnFGuK0HU"
  }
];

const VideoModal = () => {
  const [isOpen, setIsOpen] = useState({
    stabilizer: false,
    ecohoist: false
  });

  return (
    <section className="product-video-section">
      <div className="product-video-title">
        <h1
          className="main-title border-line"
          style={{ textTransform: "capitalize" }}
        >
          Innovative products only available through Godwin brands
        </h1>
      </div>

      <div className="container">
        <ul className="product-video-list">
          <li>
            <article>
              <div className="image-container">
                <img src={options[0].thumbnail} alt="" />
                <span>Telescopic Stabilizer</span>
              </div>
              <div className="image-details">
                <i
                  onClick={() =>
                    setIsOpen({
                      stabilizer: !isOpen.stabilizer,
                      ecohoist: false
                    })
                  }
                  className="far fa-play-circle"
                ></i>
                <p>
                  Seeing really is believing! Years ago Pat Godwin developed a
                  telescopic hoists stabilizing system that helps prevent truck
                  rollovers by keeping the dump body perpendicular to the truck.
                  It has since become one of our popular and request dump body
                  options!
                </p>
              </div>
            </article>
          </li>
          <li>
            <article>
              <div className="image-container">
                <img src={options[1].thumbnail} alt="" />
                <span>EcoHoist</span>
              </div>
              <div className="image-details">
                <i
                  onClick={() =>
                    setIsOpen({
                      stabilizer: false,
                      ecohoist: !isOpen.ecohoist
                    })
                  }
                  className="far fa-play-circle"
                ></i>
                <p>
                  Our EcoHoist hydraulic system was designed to maximize
                  performance, weight savings, and ease of maintenance. This
                  system allows our dump bodies to cycle up and down at nearly
                  twice the industry standard speed using less oil and less
                  weight, but with more power.
                </p>
              </div>
            </article>
          </li>
          <li>
            <a href="https://alliedmobilesystems.com/products/road-runner">
              <div className="image-container">
                <img src={options[2].thumbnail} alt="" />
                <span>Road Runner</span>
              </div>
              <div className="image-details">
                <p style={{ textTransform: "capitalize" }}>
                  Click to learn more
                </p>
                <p>
                  Have you ever wished you could control your dump and tailgate
                  by remote? Check out our RoadRunnder remote dump system. This
                  option has become especially popular with the asphalt
                  industry. One remote is capable of operating an entire fleet
                  of trucks seamlessly!
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div
        className={`product-video-overlay ${isOpen.stabilizer ? "active" : ""}`}
      >
        <div className="video-modal">
          <Modal
            channel="youtube"
            isOpen={isOpen.stabilizer}
            videoId={options[0].link}
            onClose={() =>
              setIsOpen({
                stabilizer: false,
                ecohoist: false
              })
            }
          />
        </div>
      </div>

      <div
        className={`product-video-overlay ${isOpen.ecohoist ? "active" : ""}`}
      >
        <div className="video-modal">
          <Modal
            channel="youtube"
            isOpen={isOpen.ecohoist}
            videoId={options[1].link}
            onClose={() =>
              setIsOpen({
                stabilizer: false,
                ecohoist: false
              })
            }
          />
        </div>
      </div>
    </section>
  );
};

export default VideoModal;
