import React from "react";
import { debounce } from "lodash";
/*
Steps to useContext hook
1. We use React.createContext() to create our context store. This can store anything. 
2. We create a provider function wrapping it around our top level components that we want to share the context store with
3. We pass a prop name value using our useViewport(), which returns {width:number, height: number} to our components
4. In the components that need access to the width, we import viewportContext and useContext(viewPortContext) in order to access the values passed from our provider
*/

// Import viewportContext store into a component to access the useViewport hook
export const viewportContext = React.createContext({});

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export const useViewport = () => {
  /* We can use the "useContext" Hook to acccess a context from within
       another Hook, remember, Hooks are composable! */
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

// Import ViewportProvider to your top level components to provide access to the context "store"
export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleWindowResize = debounce(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    // Returns the provider with the values from the viewportContext store.
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};
