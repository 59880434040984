import React from "react";

const urlTransformer = (url, sizes) => {
  if (!url || typeof url !== "string") return;
  if (!sizes || !Array.isArray(sizes)) return;
  // Split the url at upload
  const splitUrl = url.split("upload");
  // values that needs to be added to the url
  const values = ["upload/", "fl_lossy,", "f_auto,", "c_scale,", "q_auto,"];
  // adding w_ to the sizes array
  const w_Sizes = [];
  // result of adding values to the split url
  const transformedUrl = [];

  // add w_ to sizes
  const addWToSizes = () => {
    for (let i = 0; i < sizes.length; i++) {
      w_Sizes.push(`w_${sizes[i]}`);
    }
  };
  addWToSizes();

  // loop thru split url and add values & w_sizes
  const connectStrings = (i) => {
    let connectedUrl = [splitUrl[0], ...values, w_Sizes[i], splitUrl[1]].join(
      ""
    );
    return `${connectedUrl} ${sizes[i]}w`;
  };

  // add the values needed for the new link
  const addTransformedValues = () => {
    for (let i = 0; i < sizes.length; i++) {
      transformedUrl.push(connectStrings(i));
    }
  };
  addTransformedValues();

  return transformedUrl;
};

const TransformImage = ({ imageUrl, imageSizes, maxSize, className, alt }) => {
  return (
    <img
      sizes={maxSize}
      srcSet={urlTransformer(imageUrl, imageSizes)}
      className={className}
      src={imageUrl}
      alt={alt}
      loading="lazy"
    />
  );
};

export default TransformImage;
