import React, { useContext } from "react";
import RSVideo from "./video/rs-video.mp4";
import { motion } from "framer-motion";
import { viewportContext } from "./lib/viewportContext";

const MobileView = () => {
  return (
    <section className="video__container video__background">
      <div className="video__overlay">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1.5 }}
        >
          <span>For those who know</span>
          <br />
          <span className="logo">R/S Godwin</span>
          <br />
          <span>and won't settle for less</span>
        </motion.h1>
      </div>
    </section>
  );
};

const DesktopView = () => {
  return (
    <section className="video__container">
      <video autoPlay loop muted playsInline className="video" src={RSVideo} />
    </section>
  );
};

const Video = () => {
  const { width } = useContext(viewportContext);
  const mobileBreakPoint = 720;

  if (width < mobileBreakPoint) {
    return <MobileView />;
  }
  return <DesktopView />;
};

export default Video;
