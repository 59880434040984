import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/c-series/1_ujwh7u.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/c-series/2_r3glai.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/c-series/3_n3rfxm.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/c-series/4_ldarii.jpg"
  },
  {
    key: 5,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/c-series/5_sg0vvl.jpg"
  }
];

const CSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Steel Dump Body</h2>
        <h1 className="product-maintitle">C Series</h1>
      </div>

      <article className="product-grid">
        <TransformImage
          imageUrl={Main.SteelPhotos.CSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="C Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            For years R/S Godwin has provided a custom steel coal dump body to
            handle the tough material hauling needs of the coal industry. The C
            series body is designed to maximize hauling volume while maintaining
            structural integrity for maximized longevity.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.CSeries}
            />
          </div>
        </div>
      </article>

      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>Body lengths from 19' to 22.5'</li>
          <li>6" I-Beam longsills (17.25 lbs/ft)</li>
          <li>Full Subframe</li>
          <li>2-7/16" full length rear hinge</li>
          <li>72" body side height</li>
          <li>Vertical and horizontal side bracing</li>
          <li>1-3/4” pins for top tailgate hardware</li>
          <li>Cabshield with underside bracing</li>
          <li>Fully welded steel construction throughout</li>
          <li>Air operated tailgate hardware</li>
          <li>9 panel tailgate</li>
          <li>
            Recessed FMVSS108 L.E.D. lighting including ICC stop/tail/turn
            lights in rear posts
          </li>
          <li>Front mount telescopic hoist sized for body lengths</li>
          <li>Push bumper and push blocks</li>
          <li>PPG Delfleet Evolution and Delfleet Essential</li>
          <li>Aluminum console for in cab controls</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Various Side Heights</li>
            <li>Radius Clean out Plates</li>
            <li>Angle Around Top of Body</li>
            <li>Channel, I-Beam or Tubing crossmembers</li>
            <li>Sloped Tailgate</li>
            <li>Special crossmember Spacing</li>
            <li>Sealed Tailgate</li>
            <li>Wing Latches on Tailgate</li>
            <li>Manual Tailgate</li>
            <li>Heated Body</li>
            <li>Air/Electric Vibrators</li>
            <li>Aluminum Faced Cabshield</li>
          </ul>
          <ul>
            <li>Offset Bulkhead</li>
            <li>
              Various Side Floor Bulkhead and Tailgate Material Options
              (Including AR450)
            </li>
            <li>Overlays for Sides, Floor, Tailgate & Bulkhead</li>
            <li>Aluminum or Steel Toolboxes</li>
            <li>Various Tarp System (Mesh or Asphalt)</li>
            <li>Aluminum Hydraulic Tanks (side or front)</li>
            <li>Oak Cushion Floor</li>
            <li>Body Liners</li>
            <li>Aluminum Control Tower</li>
            <li>Lift Axles</li>
            <li>Additional Lighting</li>
            <li>Wood, Steel or Aluminum Boards</li>
          </ul>
        </div>
      </div>
      <ProductCta />
    </section>
  );
};

export default CSeries;
