import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import DropDown from "./DropDown";
import States from "./lib/states.json";

const ContactForms = () => {
  const { register, errors, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    axios({
      method: "POST",
      url: "https://formspree.io/mdowyngn",
      data
    })
      .then(() => {
        Swal.fire({
          title: "We have received your message.",
          icon: "success",
          confirmButtonText: "Thank you"
        });
        reset({
          name: "",
          email: "",
          message: ""
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <section id="contact-page">
      <div className="default-grid">
        <div className="contact-info section-1">
          <h1>Request a Quote</h1>
          <p>
            Please complete the form below and we'll pass your request to your
            nearest R/S Godwin dealer.
          </p>
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <input
                name="name"
                type="text"
                placeholder="Name *"
                required
                ref={register({ required: true, maxLength: 50 })}
              />
              {errors.name?.type === "maxLength" && (
                <span style={{ color: "red " }}>
                  You must provide a name less than 50 characters.
                </span>
              )}
            </div>
            <div className="row">
              <input
                name="email"
                type="email"
                placeholder="Email *"
                required
                ref={register({ required: true })}
              />
              {errors.email?.type === "required" && (
                <span style={{ color: "red " }}>
                  You must provide a valid email address.
                </span>
              )}
            </div>
            <div className="row">
              <select name="state" required ref={register({ required: true })}>
                <option value="">Select your state *</option>
                {States.map((state) => {
                  return (
                    <option value={state.name} key={state.abbreviation}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
              {errors.state?.type === "required" && (
                <span style={{ color: "red " }}>
                  You must select a valid state.
                </span>
              )}
            </div>
            <div className="row">
              <textarea
                name="message"
                cols="50"
                rows="10"
                type="textarea"
                placeholder="Enter your message here *"
                required
                ref={register({ required: true })}
              />
              {errors.message?.type === "required" && (
                <span style={{ color: "red " }}>You must enter a message.</span>
              )}
            </div>
            <input type="submit" className="submit-button" />
          </form>
        </div>
        <div className="contact-info section-2">
          <DropDown />
          <div className="contact-address">
            <h2 className="border-line">Visit Our Shop</h2>
            <span>Address:</span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/ewVn42Y7QvnYmXZ56"
            >
              <i className="fas fa-map-marker-alt" />
              5168 US-23, Ivel, KY 41642
            </a>
            <span>Telephone:</span>
            <a href="tel:800-826-7413">
              <i className="fas fa-mobile-alt" />
              800-826-7413
            </a>
          </div>

          <div className="contact-sales">
            <h2 className="border-line">Inside Sales or Customer Service</h2>
            <span>Email: </span>
            <a
              href="mailto:sales-rs@rsgodwin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="far fa-envelope" />
              sales-rs@rsgodwin.com
            </a>
            <span>Phone:</span>
            <a
              href="tel:606-874-7415"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-mobile-alt" />
              606-874-7415
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForms;
