import React, { useContext } from "react";
import Data from "./backend";
import { viewportContext } from "./lib/viewportContext";
import TransformImage from "./lib/TransformImage";

// Components
import Button from "./Button";
import ProductCarousel from "./ProductCarousel";

const ShowCaseBox = ({ photo, title, detail, link }) => {
  return (
    <div className="showcase-box">
      <TransformImage
        imageUrl={photo}
        imageSizes={[320, 480, 768, 1024, 1200, 1400]}
        maxSize="(max-width: 400px) 100vw, 400px"
        alt={detail}
        className="showcase-truck"
      />
      <div className="showcase-details">
        <h1>{title}</h1>
        <p>{detail}</p>
        <Button text={"Check it out"} cta={"showcase-button"} link={link} />
      </div>
    </div>
  );
};

const MobileShowcase = () => {
  return (
    <section id="showcase-section">
      <div className="showcase-title-container">
        <h1 className="showcase-title border-line">Fan Favorites</h1>
      </div>
      <div className="showcase-grid">
        <ProductCarousel photos={Data.Showcase} />
      </div>
    </section>
  );
};

const Showcase = () => {
  const { width } = useContext(viewportContext);
  const mobileBreakPoint = 720;
  if (width < mobileBreakPoint) return <MobileShowcase />;

  return (
    <section id="showcase-section">
      <div className="showcase-title-container">
        <h1 className="showcase-title border-line">Fan Favorites</h1>
      </div>
      <div className="showcase-gallery showcase-grid">
        {Data.Showcase.map((el) => {
          return (
            <ShowCaseBox
              photo={el.photo}
              title={el.Title}
              detail={el.Detail}
              link={el.Link}
              key={el.key}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Showcase;
