import React, { useState } from "react";
import states from "./lib/states.json";
import sales from "./lib/sales.json";

const DropDown = () => {
  const [salesRep, setSalesRep] = useState([]);

  const handleChange = (e) => {
    const stateSelected = e.target.value;
    filteredSalesRep(stateSelected);
  };

  const filteredSalesRep = (stateSelected) => {
    const filteredResults = sales.filter((state) =>
      state.territory.includes(stateSelected)
    );
    setSalesRep(() => filteredResults);
  };

  const sortByFirstName = (a, b) => {
    const name1 = a.name;
    const name2 = b.name;

    return name1 === name2 ? 0 : name1 < name2 ? -1 : 1;
  };

  return (
    <section className="sales-section">
      <h2 className="border-line">Find a sales representative</h2>
      <img
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjMuMTExIDIwLjA1OGwtNC45NzctNC45NzdjLjk2NS0xLjUyIDEuNTIzLTMuMzIyIDEuNTIzLTUuMjUxIDAtNS40Mi00LjQwOS05LjgzLTkuODI5LTkuODMtNS40MiAwLTkuODI4IDQuNDEtOS44MjggOS44M3M0LjQwOCA5LjgzIDkuODI5IDkuODNjMS44MzQgMCAzLjU1Mi0uNTA1IDUuMDIyLTEuMzgzbDUuMDIxIDUuMDIxYzIuMTQ0IDIuMTQxIDUuMzg0LTEuMDk2IDMuMjM5LTMuMjR6bS0yMC4wNjQtMTAuMjI4YzAtMy43MzkgMy4wNDMtNi43ODIgNi43ODItNi43ODJzNi43ODIgMy4wNDIgNi43ODIgNi43ODItMy4wNDMgNi43ODItNi43ODIgNi43ODItNi43ODItMy4wNDMtNi43ODItNi43ODJ6bTIuMDEtMS43NjRjMS45ODQtNC41OTkgOC42NjQtNC4wNjYgOS45MjIuNzQ5LTIuNTM0LTIuOTc0LTYuOTkzLTMuMjk0LTkuOTIyLS43NDl6Ii8+PC9zdmc+"
        alt="Search icon"
      />
      <select onChange={(e) => handleChange(e)}>
        <option value="">Select your state</option>
        {states.map((state) => {
          return (
            <option key={state.abbreviation} value={state.abbreviation}>
              {state.name}
            </option>
          );
        })}
      </select>

      <div>
        {salesRep.length ? (
          salesRep.sort(sortByFirstName).map((rep) => (
            <div key={rep.name} className="sales-rep-card">
              <p>
                Name: <span>{rep.name}</span>
              </p>
              {rep.company ? (
                <p>
                  Company: <span>{rep.company}</span>
                </p>
              ) : null}
              {rep.phone ? (
                <p>
                  Phone: <a href={`tel:${rep.phone}`}>{rep.phone}</a>
                </p>
              ) : null}
              {rep.email ? (
                <p>
                  Email:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${rep.email}`}
                  >
                    {rep.email}
                  </a>
                </p>
              ) : null}
              <div class="sales-rep-territory">
                <p>Territories Covered: </p>
                {rep.territory
                  ? rep.territory.map((state) => {
                      return <p key={state}>{state}</p>;
                    })
                  : null}
              </div>
            </div>
          ))
        ) : (
          <p>No reps found</p>
        )}
      </div>
    </section>
  );
};

export default DropDown;
