import React, { useState } from "react";

// Components
import Splash from "./Splash";
import steel50K from "./images/materials/materials_50k.jpg";
import steelAr450 from "./images/materials/materials_AR450.jpg";
import alumAlloy from "./images/materials/materials_alum.jpg";

const MaterialTable = () => {
  const [toggle, setToggle] = useState({
    grade50: true,
    ar450: false,
    aluminum: false,
    hardox500: false
  });

  const { grade50, ar450, aluminum, hardox500 } = toggle;

  return (
    <>
      <Splash image={"material-table-banner"} text={"Built to last."} />
      <section id="material-table">
        <div className="material-table-buttons">
          <h1 className="main-title border-line">What we offer</h1>
          <div className="material-button-container">
            <button
              className={`material-table-links ${grade50 ? "active" : ""}`}
              onClick={() =>
                setToggle({
                  grade50: !grade50,
                  ar450: false,
                  aluminum: false,
                  hardox500: false
                })
              }
            >
              HSLA A1011 Grade 50
            </button>
            <button
              className={`material-table-links ${aluminum ? "active" : ""}`}
              onClick={() =>
                setToggle({
                  grade50: false,
                  ar450: false,
                  aluminum: false,
                  hardox500: !hardox500
                })
              }
            >
              Hardox 500 Tuf Steel
            </button>
            <button
              className={`material-table-links ${ar450 ? "active" : ""}`}
              onClick={() =>
                setToggle({
                  grade50: false,
                  ar450: !ar450,
                  aluminum: false,
                  hardox500: false
                })
              }
            >
              AR450 Hardox Steel
            </button>
            <button
              className={`material-table-links ${aluminum ? "active" : ""}`}
              onClick={() =>
                setToggle({
                  grade50: false,
                  ar450: false,
                  aluminum: !aluminum,
                  hardox500: false
                })
              }
            >
              Aluminum Alloy
            </button>
          </div>
        </div>
        <section className="material-table-info">
          {grade50 ? (
            <article>
              <img src={steel50K} alt="Steel" />
              <p className="main-info">
                Grade 50 steel is probably the second most commonly used
                material in the dump body industry. The most common is A36
                steel. A 36 is significantly weaker than grade 50, and we feel
                it's unsuitable to use in a quality dump body. Grade 50 is ideal
                for general use bodies which aren't going to be used to haul
                highly abrasive and high impact materials such as boulders and
                demo materials. 50K is ideal for asphalt, sand, dirt, gravel,
                and other small aggregates. It's important to understand that
                all Grade 50 materials are not equal. Grade 50 steel is supposed
                to be 50,000 lbs minimum yield, though some body manufactures
                use uncertified secondary material that may be lesser quality.
                RS Godwin purchases only top quality "premium" mill certified
                Grade 50 steel. All of RS Godwin's Grade 50 steel is ASTM A1011
                certified to ensure quality and consistency. To further enhance
                quality assurance, RS Godwin purchases their Grade 50 steel from
                an American ISO 9001:2000 certified mill. RS Godwin absolutely
                refuses to purchase secondary grade materials.
              </p>
              <p className="main-info">
                HSLA material provides more formability when bending. HSLA
                material will contain more columbium and vanadium than material
                that is simply a structural Grade 50. This is a benefit to the
                manufacturer as well as the customer. The use of HSLA Grade 50
                steel allows for higher consistency and precision in the
                manufacturing process giving the purchaser a higher level of
                craftsmanship in their body.
              </p>
              <ul className="main-info">
                <li>Least costly material used by RS Godwin</li>
                <li>Ideal for general purpose hauling</li>
                <li>Most popular dump body material</li>
                <li>Most readily available material</li>
              </ul>
            </article>
          ) : (
            ""
          )}
          {ar450 ? (
            <article>
              <img src={steelAr450} alt="Steel" />
              <p className="main-info">
                AR450 Hardox steel is an abrasion resistant wear plate
                manufactured by industry leading high strength steel
                manufacturer SSAB. Hardox is a brand of SSAB similar to Domex.
                Hardox steel is ideal for bodies which will be used for hauling
                very abrasive and high impact materials such as boulders, demo
                materials and medium to large aggregate. Hardox 450 has a yield
                strength of around 175,000 lbs minimum yield. That is 75%
                stronger than 100K steel, and 250% stronger than 50K steel. The
                strength of AR450 steel can improve weight savings over 50K much
                like 100K material because a thinner material can be used
                without sacrificing strength. RS Godwin uses only premium ASTM
                certified AR450 Hardox material and accepts no substitutes.
                Hardox steel arrives at the RS Godwin facility after each plate
                has been shot blasted and primed to create an ideal working
                surface. A few steel manufactures offer a limited selection of
                AR materials while other steel manufactures are only getting
                started in their development of AR steel. Non have the name
                recognition and reputation of Hardox.
              </p>
              <p className="main-info">
                AR450 is considerably more expensive than 50K and 100K, but
                bodies constructed of AR450 steel are much more rugged and
                durable in abusive environments. AR450 steel used in a general
                purpose Body would certainly extend longevity.
              </p>
              <ul className="main-info">
                <li>250% stronger than 100K steel</li>
                <li>Super abrasive resistant</li>
                <li>Resistant to deformity due to heavy impact</li>
                <li>Extremely durable</li>
                <li>Offers weight savings without sacrificing strength</li>
                <li>Ideal for more abusive material hauling needs</li>
                <li>Less readily available than 50K and 100K steel</li>
              </ul>
              <p className="main-info">
                Due to the nature of the AR material, the finish isn't quite as
                smooth as 100K or 50K steel. It is described as having a very
                fine "orange peel" appearance after paint is applied. Most
                customers see this as a minor trade off for the added
                durability. AR wear plate is often used in off-road excavating
                buckets and scraper applications. AR steel is also used as
                bullet resistant armor in military vehicles.
              </p>
            </article>
          ) : (
            ""
          )}
          {aluminum ? (
            <article>
              <img src={alumAlloy} alt="Steel" />
              <p className="main-info">
                RS Godwin uses Aluminum Alloys in the construction of it's
                Hybrid series and all aluminum bodies. These materials are
                always premium ASTM certified. RS Godwin primarily uses 1/4" and
                3/16" 5454-H34 Brite aluminum plate, and 6061-T6 aluminum alloy
                extrusions. Just as with steel, R/S uses prime grade material
                and refuses to use secondary grades. Both plate and extrusions
                aluminum materials can be polished if desired.
              </p>
              <ul className="main-info">
                <li>Lighter alternative to steel</li>
                <li>Often more attractive steel</li>
                <li>Can be polished to desired finish</li>
                <li>Less corrosive than steel</li>
                <li>Less durable than steel</li>
              </ul>
              <p className="main-info">
                Aluminum products can offer significant weight savings over
                steel in light to medium duty applications. Though heavier,
                steel is far more forgiving than aluminum in heavy duty and
                abrasive applications.
              </p>
            </article>
          ) : (
            ""
          )}
          {hardox500 ? (
            <article>
              <p className="main-info">New product, details coming soon.</p>
            </article>
          ) : (
            ""
          )}
        </section>
      </section>
    </>
  );
};

export default MaterialTable;
