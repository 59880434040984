import React from "react";

// Components
import Materials from "../Materials";
import MaterialTable from "../MaterialTable";

const MaterialPage = () => {
  return (
    <>
      <Materials />
      <MaterialTable />
    </>
  );
};

export default MaterialPage;
