import React from "react";

const Splash = ({ image, text }) => {
  return (
    <section
      className={image ? `splash-bg ${image} cld-responsive` : `splash-bg`}
    >
      {text ? <h1 className="main-title">{text}</h1> : ""}
    </section>
  );
};

export default Splash;
