import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import Routes from "./backend";

const NavPanel = ({ menu, setToggle }) => {
  const [openMenu, setOpenMenu] = useState({
    products: false,
    resources: false,
    about: false
  });
  const [openProducts, setOpenProducts] = useState({
    steel: false,
    alum: false,
    hybrid: false
  });

  useEffect(() => {
    const navPanel = document.querySelector(".navbar-content-mobile");

    const closeMenu = () => {
      setToggle({ menu: false });
      setOpenMenu({
        products: false,
        resources: false,
        about: false
      });
    };

    const escapeNavPanel = (e) => (e.keyCode === 27 ? closeMenu() : null);

    // stop scrollY if nav menu is open
    if (menu) {
      disableBodyScroll(navPanel);
      document.addEventListener("keydown", (e) => escapeNavPanel(e));
    }
    // remove scrollY lock when nav menu is closed
    return () => {
      clearAllBodyScrollLocks();
      document.removeEventListener("keydown", escapeNavPanel);
    };
  }, [menu]);

  const {
    CSeriesRoute,
    CsSeriesRoute,
    GSeriesRoute,
    ElSeriesRoute,
    ScSeriesRoute,
    AcSeriesRoute,
    AgSeriesRoute,
    AuSeriesRoute,
    DoubleWallSeriesRoute,
    StandardSeriesRoute
  } = Routes.Products;

  const {
    CompanyPageRoute,
    PaintProcessRoute,
    ContactPageRoute,
    MaterialPageRoute,
    LiteraturePageRoute
  } = Routes.Pages;

  const closeMenu = () => {
    setToggle({ menu: false });
    setOpenMenu({
      products: false,
      resources: false,
      about: false
    });
  };

  return (
    <div className={`nav-panel-container ${menu ? `open` : ""}`}>
      <div className="nav-overlay" onClick={() => closeMenu()} />
      <nav className={`nav-wrapper ${menu ? `open` : ""}`}>
        <div className="nav-content">
          <ul
            className={`menu-bar ${
              openMenu.products || openMenu.resources || openMenu.about
                ? `open`
                : ""
            }`}
          >
            <li
              className="product-item"
              onClick={() =>
                setOpenMenu({
                  products: !openMenu.products,
                  resources: false,
                  about: false
                })
              }
            >
              Products <i className="fas fa-angle-right"></i>
            </li>
            <li
              className="resource-item"
              onClick={() =>
                setOpenMenu({
                  products: false,
                  resources: !openMenu.resources,
                  about: false
                })
              }
            >
              Resources <i className="fas fa-angle-right"></i>
            </li>
            <li
              className="about-item"
              onClick={() =>
                setOpenMenu({
                  products: false,
                  resources: false,
                  about: !openMenu.about
                })
              }
            >
              About <i className="fas fa-angle-right"></i>
            </li>
            <li>
              <Link to={ContactPageRoute} onClick={() => closeMenu()}>
                Contact Us
              </Link>
            </li>
          </ul>

          <ul
            className={`product-drop ${openMenu.products ? `show-item` : ""}`}
          >
            <li
              className="arrow back-product-btn"
              onClick={() => {
                setOpenProducts({
                  steel: false,
                  alum: false,
                  hybrid: false
                });
                setOpenMenu({
                  products: !openMenu.products,
                  resources: false,
                  about: false
                });
              }}
            >
              <span className="fas fa-arrow-left"></span>Products
            </li>
            <li
              className="steel-btn"
              onClick={() =>
                setOpenProducts({
                  steel: !openProducts.steel,
                  alum: false,
                  hybrid: false
                })
              }
            >
              Steel Bodies
              <i
                className={`fas fa-caret-down ${
                  openProducts.steel ? `rotate` : ""
                }`}
              ></i>
              <ul
                className={`steel-products ${openProducts.steel ? `show` : ""}`}
              >
                <li>
                  <Link to={CSeriesRoute} onClick={() => closeMenu()}>
                    C Series
                  </Link>
                </li>
                <li>
                  <Link to={CsSeriesRoute} onClick={() => closeMenu()}>
                    CS Series
                  </Link>
                </li>
                <li>
                  <Link to={ElSeriesRoute} onClick={() => closeMenu()}>
                    El Series
                  </Link>
                </li>
                <li>
                  <Link to={GSeriesRoute} onClick={() => closeMenu()}>
                    G Series
                  </Link>
                </li>
                <li>
                  <Link to={ScSeriesRoute} onClick={() => closeMenu()}>
                    Sc Series
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="alu-btn"
              onClick={() =>
                setOpenProducts({
                  steel: false,
                  alum: !openProducts.alum,
                  hybrid: false
                })
              }
            >
              Alumnium Bodies
              <i
                className={`fas fa-caret-down ${
                  openProducts.alum ? `rotate` : ""
                }`}
              ></i>
              <ul className={`alu-products ${openProducts.alum ? `show` : ""}`}>
                <li>
                  <Link to={AcSeriesRoute} onClick={() => closeMenu()}>
                    AC Series
                  </Link>
                </li>
                <li>
                  <Link to={AgSeriesRoute} onClick={() => closeMenu()}>
                    AG Series
                  </Link>
                </li>
                <li>
                  <Link to={AuSeriesRoute} onClick={() => closeMenu()}>
                    AU Series
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="hybrid-btn"
              onClick={() =>
                setOpenProducts({
                  steel: false,
                  alum: false,
                  hybrid: !openProducts.hybrid
                })
              }
            >
              Hybrid Bodies
              <i
                className={`fas fa-caret-down ${
                  openProducts.hybrid ? `rotate` : ""
                }`}
              ></i>
              <ul
                className={`hybrid-products ${
                  openProducts.hybrid ? `show` : ""
                }`}
              >
                <li>
                  <Link to={DoubleWallSeriesRoute} onClick={() => closeMenu()}>
                    Double Walled Series
                  </Link>
                </li>
                <li>
                  <Link to={StandardSeriesRoute} onClick={() => closeMenu()}>
                    Standard Series
                  </Link>
                </li>
              </ul>
            </li>
          </ul>

          <ul
            className={`resource-drop ${openMenu.resources ? `show-item` : ""}`}
          >
            <li
              className="arrow back-resource-btn"
              onClick={() =>
                setOpenMenu({
                  products: false,
                  resources: !openMenu.resources,
                  about: false
                })
              }
            >
              <span className="fas fa-arrow-left"></span>Resources
            </li>
            <li>
              <Link to={PaintProcessRoute} onClick={() => closeMenu()}>
                Paint Process
              </Link>
            </li>
            <li>
              <Link to={MaterialPageRoute} onClick={() => closeMenu()}>
                Materials
              </Link>
            </li>
            <li>
              <Link to={LiteraturePageRoute} onClick={() => closeMenu()}>
                Literature
              </Link>
            </li>
          </ul>

          <ul className={`about-drop ${openMenu.about ? `show-item` : ""}`}>
            <li
              className="arrow back-about-btn"
              onClick={() =>
                setOpenMenu({
                  products: false,
                  resources: false,
                  about: !openMenu.about
                })
              }
            >
              <span className="fas fa-arrow-left"></span>About
            </li>
            <li>
              <Link to={CompanyPageRoute} onClick={() => closeMenu()}>
                Our Company
              </Link>
            </li>
            <li>
              <a
                href="http://godwingrouponline.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Affiliates
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavPanel;
