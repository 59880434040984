import React, { useState } from "react";
import { Image, Transformation } from "cloudinary-react";
import { Link } from "react-router-dom";
import useScrollHeight from "./lib/useScrollHeight";

// Components
import NavBarLinks from "./NavBarLinks";
import NavPanel from "./NavPanel";

// Product Links
import Routes from "./backend";
import Products from "./backend";

const Header = () => {
  const [toggle, setToggle] = useState({
    product: false,
    resources: false,
    about: false,
    menu: false
  });

  const scroll = useScrollHeight();

  const {
    CompanyPageRoute,
    PaintProcessRoute,
    MaterialPageRoute,
    LiteraturePageRoute
  } = Routes.Pages;

  const { product, resources, about, menu } = toggle;

  const clickHandler = () => {
    return setToggle({
      product: false
    });
  };

  return (
    <header className={`${scroll > 0 ? `navbar-box-shadow` : ``}`}>
      <NavBarLinks
        product={product}
        resources={resources}
        about={about}
        menu={menu}
        setToggle={setToggle}
        buttonLink={Routes.Pages.ContactPageRoute}
        homeLink={Routes.Pages.HomePageRoute}
      />
      <div
        className={`dropdown ${product ? "show" : "hide"}`}
        onMouseLeave={() =>
          setToggle({
            product: false
          })
        }
      >
        <div className="dropdown-container">
          <div className="product grid">
            <a
              className="product__anchor"
              href="/"
              onClick={(e) => e.preventDefault()}
            >
              <h3>Steel Bodies</h3>
            </a>
            <div className="product__drop-down">
              <div className="product__container">
                {Products.SteelSeries.map((product) => {
                  return (
                    <div className="product__cards" key={product.name}>
                      <Image
                        cloudName="clee"
                        publicId={product.photo}
                        className="product__image"
                        loading="lazy"
                        alt={product.name}
                      >
                        <Transformation width="512" crop="scale" />
                      </Image>
                      <Link
                        className="product__cards--anchor"
                        to={product.link}
                        onClick={() => clickHandler()}
                      >
                        <p>{product.name}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="product grid">
            <a
              className="product__anchor"
              href="/"
              onClick={(e) => e.preventDefault()}
            >
              <h3>Aluminum Bodies</h3>
            </a>

            <div className="product__drop-down">
              <div className="product__container">
                {Products.AluminumSeries.map((product) => {
                  return (
                    <div className="product__cards" key={product.name}>
                      <Image
                        cloudName="clee"
                        publicId={product.photo}
                        className="product__image"
                        loading="lazy"
                        alt={product.name}
                      >
                        <Transformation width="512" crop="scale" />
                      </Image>
                      <Link
                        className="product__cards--anchor"
                        to={product.link}
                        onClick={() => clickHandler()}
                      >
                        <p>{product.name}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="product grid">
            <a
              className="product__anchor"
              href="/"
              onClick={(e) => e.preventDefault()}
            >
              <h3>Hybrid Bodies</h3>
            </a>

            <div className="product__drop-down">
              <div className="product__container">
                {Products.HybridSeries.map((product) => {
                  return (
                    <div className="product__cards" key={product.name}>
                      <Image
                        cloudName="clee"
                        publicId={product.photo}
                        className="product__image"
                        loading="lazy"
                        alt={product.name}
                      >
                        <Transformation width="512" crop="scale" />
                      </Image>
                      <Link
                        className="product__cards--anchor"
                        to={product.link}
                        onClick={() => clickHandler()}
                      >
                        <p>{product.name}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`dropdown ${resources ? "show" : "hide"}`}
        onMouseLeave={() =>
          setToggle({
            resources: false
          })
        }
      >
        <div className="dropdown-container">
          <div className="resource">
            <Link
              className="resource__anchor"
              to={PaintProcessRoute}
              onClick={() => clickHandler()}
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNCAxOWgtMXYtMi4yYy0xLjg1MyA0LjIzNy02LjA4MyA3LjItMTEgNy4yLTYuNjIzIDAtMTItNS4zNzctMTItMTJoMWMwIDYuMDcxIDQuOTI5IDExIDExIDExIDQuNjYgMCA4LjY0Ny0yLjkwNCAxMC4yNDktN2gtMi4yNDl2LTFoNHY0em0tMTEuMDM2IDBoLTEuODg2Yy0uMzQtLjk1Ny0uNDM3LTEuNTcxLTEuMTc3LTEuODc4aC0uMDAxYy0uNzQzLS4zMDgtMS4yNTEuMDYxLTIuMTYyLjQ5NGwtMS4zMzMtMS4zMzNjLjQyNy0uODk5LjgwNC0xLjQxNS40OTQtMi4xNjMtLjMwOC0uNzQtLjkyNi0uODM5LTEuODc4LTEuMTc3di0xLjg4NmMuOTU0LS4zMzkgMS41Ny0uNDM3IDEuODc4LTEuMTc4LjMwOC0uNzQzLS4wNi0xLjI0OC0uNDk0LTIuMTYybDEuMzMzLTEuMzMzYy45MTguNDM2IDEuNDIxLjgwMSAyLjE2Mi40OTRsLjAwMS0uMDAxYy43NC0uMzA3LjgzOC0uOTI0IDEuMTc3LTEuODc3aDEuODg2Yy4zNC45NTguNDM3IDEuNTcgMS4xNzcgMS44NzdsLjAwMS4wMDFjLjc0My4zMDggMS4yNTItLjA2MiAyLjE2Mi0uNDk0bDEuMzMzIDEuMzMzYy0uNDM1LjkxNy0uODAxIDEuNDIxLS40OTQgMi4xNjF2LjAwMWMuMzA3LjczOS45MTUuODM1IDEuODc4IDEuMTc4djEuODg2Yy0uOTUzLjMzOC0xLjU3MS40MzctMS44NzggMS4xNzgtLjMwOC43NDMuMDYgMS4yNDkuNDk0IDIuMTYybC0xLjMzMyAxLjMzM2MtLjkyLS40MzgtMS40Mi0uODAyLTIuMTU3LS40OTYtLjc0Ni4zMS0uODQ0LjkyNi0xLjE4MyAxLjg4em0tLjk0My00LjY2N2MtMS4yODkgMC0yLjMzMy0xLjA0NC0yLjMzMy0yLjMzMyAwLTEuMjg5IDEuMDQ0LTIuMzM0IDIuMzMzLTIuMzM0IDEuMjg5IDAgMi4zMzMgMS4wNDUgMi4zMzMgMi4zMzQgMCAxLjI4OS0xLjA0NCAyLjMzMy0yLjMzMyAyLjMzM3ptLTguMDIxLTUuMzMzaC00di00aDF2Mi4yYzEuODUzLTQuMjM3IDYuMDgzLTcuMiAxMS03LjIgNi42MjMgMCAxMiA1LjM3NyAxMiAxMmgtMWMwLTYuMDcxLTQuOTI5LTExLTExLTExLTQuNjYgMC04LjY0NyAyLjkwNC0xMC4yNDkgN2gyLjI0OXYxeiIvPjwvc3ZnPg=="
                alt="Painting process"
              />
              <h3>Painting Process</h3>
            </Link>
          </div>
          <div className="resource">
            <Link
              className="resource__anchor"
              to={MaterialPageRoute}
              onClick={() => clickHandler()}
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDZoMTFsMS0zaDd2MWgtNWwtMyAxMGgtOWwtMi04em0zLjk5NSAyaC0uOTk1bDEuMDM2IDRoMS4wNTdsLTEuMDk4LTR6bTQuMTg2IDBoLS45OTVsMS4wMzYgNGgxLjA1N2wtMS4wOTgtNHptLTIuMDkzIDBoLS45OTVsMS4wMzYgNGgxLjA1N2wtMS4wOTgtNHptLjQ4My02Ljc5MWMuMTk4LS4xMzUuNDQzLS4yMDkuNjkyLS4yMDkuMzcyIDAgLjc1NC4xNjcgMSAuNTUzLjg1MSAxLjMzNyAyLjIwNyAzLjQ0NyAyLjIwNyAzLjQ0N2gtOS40N3MzLjcwNi0yLjUyNCA1LjU3MS0zLjc5MXptNC40MjkgMTcuNzkxdi4wMjZjLS4wMTQgMi4xOTYtMS44MDEgMy45NzQtNCAzLjk3NC0yLjIwOCAwLTQtMS43OTItNC00IDAtLjcyOS4xOTUtMS40MTEuNTM2LTJoLTEuNTM2di0yaDkuOTJsMy4wOC0xMGg0LjI3OWMuNDMxIDAgLjgxMy4yNzUuOTQ5LjY4NGwxLjc3MiA1LjMxNiAxLjMzNy42NjhjLjQwNi4yMDQuNjYzLjYxOS42NjMgMS4wNzN2NC4yNTljMCAuNTUzLS40NDcgMS0xIDFoLTEuMTI2Yy4wODIuMzE5LjEyNi42NTUuMTI2IDEgMCAyLjIwOC0xLjc5MiA0LTQgNHMtNC0xLjc5Mi00LTRoLTN6bS00LTEuNWMuODI4IDAgMS41LjY3MiAxLjUgMS41cy0uNjcyIDEuNS0xLjUgMS41LTEuNS0uNjcyLTEuNS0xLjUuNjcyLTEuNSAxLjUtMS41em0xMSAwYy44MjggMCAxLjUuNjcyIDEuNSAxLjVzLS42NzIgMS41LTEuNSAxLjUtMS41LS42NzItMS41LTEuNS42NzItMS41IDEuNS0xLjV6bS45OS0xMC41aC0yLjk5djNoMy44ODlsLS44OTktM3oiLz48L3N2Zz4="
                alt="Dump truck material"
              />
              <h3>Materials</h3>
            </Link>
          </div>
          <div className="resource">
            <Link
              className="resource__anchor"
              to={LiteraturePageRoute}
              onClick={() => clickHandler()}
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgNC43MDZjLTIuOTM4LTEuODMtNy40MTYtMi41NjYtMTItMi43MDZ2MTcuNzE0YzMuOTM3LjEyIDcuNzk1LjY4MSAxMC42NjcgMS45OTUuODQ2LjM4OCAxLjgxNy4zODggMi42NjcgMCAyLjg3Mi0xLjMxNCA2LjcyOS0xLjg3NSAxMC42NjYtMS45OTV2LTE3LjcxNGMtNC41ODQuMTQtOS4wNjIuODc2LTEyIDIuNzA2em0tMTAgMTMuMTA0di0xMy43MDRjNS4xNTcuMzg5IDcuNTI3IDEuNDYzIDkgMi4zMzR2MTMuMTY4Yy0xLjUyNS0uNTQ2LTQuNzE2LTEuNTA0LTktMS43OTh6bTIwIDBjLTQuMjgzLjI5My03LjQ3NSAxLjI1Mi05IDEuNzk5di0xMy4xNzFjMS40NTMtLjg2MSAzLjgzLTEuOTQyIDktMi4zMzJ2MTMuNzA0em0tMi0xMC4yMTRjLTIuMDg2LjMxMi00LjQ1MSAxLjAyMy02IDEuNjcydi0xLjA2NGMxLjY2OC0uNjIyIDMuODgxLTEuMzE1IDYtMS42MjZ2MS4wMTh6bTAgMy4wNTVjLTIuMTE5LjMxMS00LjMzMiAxLjAwNC02IDEuNjI2djEuMDY0YzEuNTQ5LS42NDkgMy45MTQtMS4zNjEgNi0xLjY3M3YtMS4wMTd6bTAtMi4wMzFjLTIuMTE5LjMxMS00LjMzMiAxLjAwNC02IDEuNjI2djEuMDY0YzEuNTQ5LS42NDkgMy45MTQtMS4zNjEgNi0xLjY3M3YtMS4wMTd6bTAgNi4wOTNjLTIuMTE5LjMxMS00LjMzMiAxLjAwNC02IDEuNjI2djEuMDY0YzEuNTQ5LS42NDkgMy45MTQtMS4zNjEgNi0xLjY3M3YtMS4wMTd6bTAtMi4wMzFjLTIuMTE5LjMxMS00LjMzMiAxLjAwNC02IDEuNjI2djEuMDY0YzEuNTQ5LS42NDkgMy45MTQtMS4zNjEgNi0xLjY3M3YtMS4wMTd6bS0xNi02LjEwNGMyLjExOS4zMTEgNC4zMzIgMS4wMDQgNiAxLjYyNnYxLjA2NGMtMS41NDktLjY0OS0zLjkxNC0xLjM2MS02LTEuNjcydi0xLjAxOHptMCA1LjA5YzIuMDg2LjMxMiA0LjQ1MSAxLjAyMyA2IDEuNjczdi0xLjA2NGMtMS42NjgtLjYyMi0zLjg4MS0xLjMxNS02LTEuNjI2djEuMDE3em0wLTIuMDMxYzIuMDg2LjMxMiA0LjQ1MSAxLjAyMyA2IDEuNjczdi0xLjA2NGMtMS42NjgtLjYyMi0zLjg4MS0xLjMxNi02LTEuNjI2djEuMDE3em0wIDYuMDkzYzIuMDg2LjMxMiA0LjQ1MSAxLjAyMyA2IDEuNjczdi0xLjA2NGMtMS42NjgtLjYyMi0zLjg4MS0xLjMxNS02LTEuNjI2djEuMDE3em0wLTIuMDMxYzIuMDg2LjMxMiA0LjQ1MSAxLjAyMyA2IDEuNjczdi0xLjA2NGMtMS42NjgtLjYyMi0zLjg4MS0xLjMxNS02LTEuNjI2djEuMDE3eiIvPjwvc3ZnPg=="
                alt="Literature icon"
              />
              <h3>Literature</h3>
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`dropdown ${about ? "show" : "hide"}`}
        onMouseLeave={() =>
          setToggle({
            about: false
          })
        }
      >
        <div className="dropdown-container">
          <div className="about">
            <Link
              className="about__anchor"
              to={CompanyPageRoute}
              onClick={() => clickHandler()}
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xLjk4MSA4LjQ0NGgyMC4wMzhjLjM5OCAwIC43NDcuMjY0Ljg1Ni42NDhsMS4xMDUgMy45MDQuMDIuMTM5YzAgLjIwOS0uMTI3LjQwMi0uMzMuNDhsLS4wMDEuMDAxYy0uMjQuMDkyLS41MTEtLjAwNS0uNjM1LS4yMzFsLTEuMTQ0LTIuMDcxLS4zMjggNy45NjdjLS4wMTcuNDAzLS4zNDcuNzE5LS43NDkuNzE5aC0uMDAxYy0uMzkzIDAtLjcxNi0uMzA2LS43NDYtLjY5OC0uMDY4LS44NjUtLjI0OS0yLjkzMy0uMzA0LTMuNzUyLS4wMjItLjM0LS4yNzEtLjU0LS41NDEtLjU0LS4yNDIgMC0uNTE0LjItLjUzNy41NC0uMDU1LjgxOS0uMjM2IDIuODg3LS4zMDQgMy43NTItLjAzLjM5Mi0uMzUyLjY5OC0uNzQ2LjY5OGgtLjAwMWMtLjQwMiAwLS43MzItLjMxNi0uNzQ5LS43MTktLjA4Ni0yLjA4LS40MzUtOC43MzYtLjQzNS04LjczNmgtMS42NjlzLS4zNDkgNi42NTYtLjQzNSA4LjczNmMtLjAxNy40MDItLjM0Ny43MTktLjc0OS43MTloLS4wMDFjLS4zOTQgMC0uNzE2LS4zMDYtLjc0Ni0uNjk4LS4wNjgtLjg2NS0uMjQ5LTIuOTMzLS4zMDQtMy43NTItLjAyMy0uMzQtLjI5NS0uNTQtLjUzNy0uNTRoLS4wMDRjLS4yNDIgMC0uNTE1LjItLjUzNy41NC0uMDU1LjgxOS0uMjM2IDIuODg3LS4zMDQgMy43NTItLjAzLjM5Mi0uMzUzLjY5OC0uNzQ2LjY5OGgtLjAwMWMtLjQwMiAwLS43MzItLjMxNi0uNzQ5LS43MTktLjA4Ni0yLjA4LS40MzUtOC43MzYtLjQzNS04LjczNmgtMS42ODFzLS4zNDkgNi42NTYtLjQzNSA4LjczNmMtLjAxNy40MDMtLjM0Ny43MTktLjc0OS43MTloLS4wMDFjLS4zOTQgMC0uNzE2LS4zMDYtLjc0Ni0uNjk4LS4wNjgtLjg2NS0uMjQ5LTIuOTMzLS4zMDQtMy43NTItLjAyMy0uMzQtLjI5NS0uNTQtLjUzNy0uNTQtLjI3IDAtLjUxOS4yLS41NDEuNTQtLjA1NS44MTktLjIzNiAyLjg4Ny0uMzA0IDMuNzUyLS4wMy4zOTItLjM1My42OTgtLjc0Ni42OThoLS4wMDFjLS40MDIgMC0uNzMyLS4zMTYtLjc0OS0uNzE5bC0uMzI4LTcuOTY3LTEuMTQ0IDIuMDcxYy0uMTI0LjIyNi0uMzk1LjMyMy0uNjM1LjIzMWwtLjAwMS0uMDAxYy0uMjAzLS4wNzgtLjMzLS4yNzEtLjMzLS40OGwuMDItLjEzOSAxLjEwNS0zLjkwNGMuMTA5LS4zODQuNDU4LS42NDguODU2LS42NDh6bTMuMDE5LTQuNDQ0YzEuMTA0IDAgMiAuODk2IDIgMnMtLjg5NiAyLTIgMi0yLS44OTYtMi0yIC44OTYtMiAyLTJ6bTE0IDBjLTEuMTA0IDAtMiAuODk2LTIgMnMuODk2IDIgMiAyIDItLjg5NiAyLTItLjg5Ni0yLTItMnptLTYuOTk0IDBjMS4xMDQgMCAyIC44OTYgMiAycy0uODk2IDItMiAyLTItLjg5Ni0yLTIgLjg5Ni0yIDItMnoiLz48L3N2Zz4="
                alt="Family icon"
              />
              <h3>About RS Godwin</h3>
            </Link>
          </div>
          <div className="about">
            <a
              className="about__anchor"
              target="_blank"
              rel="noopener noreferrer"
              href="http://godwingrouponline.com/"
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjQgMjIuNTg2bC0yLjgyMy0yLjgyM2MuNTI2LS43OTIuODM2LTEuNzQuODM2LTIuNzYzIDAtMi43NjItMi4yMzgtNS01LTVzLTUgMi4yMzgtNSA1IDIuMjM4IDUgNSA1YzEuMDE2IDAgMS45NTctLjMwNyAyLjc0Ni0uODI3bDIuODI3IDIuODI3IDEuNDE0LTEuNDE0em0tOS45ODctNS41ODZjMC0xLjY1NCAxLjM0Ni0zIDMtM3MzIDEuMzQ2IDMgMy0xLjM0NiAzLTMgMy0zLTEuMzQ2LTMtM3ptLTQgMGwuMDAyLS4wMzQtMy4wMTUgMi4xNzV2LTEzLjA2OGw0LTIuODg2djEwLjI0N2MuNTA4LS44NTQgMS4xODktMS41OTEgMi0yLjE2MXYtOC4wODZsNCAyLjg4NnYzLjkyN2guMDEzYy4zMzYgMCAuNjY0LjAzMi45ODcuMDc4di00LjAwN2w0LTIuNDc5djguNTA0YzEuMTg4IDEuMjA4IDEuOTM2IDIuODQ0IDIgNC42NTN2LTE2Ljc0OWwtNi40NTUgNC01LjU0NS00LTUuNTQ1IDQtNi40NTUtNHYxOGw2LjQ1NSA0IDMuOTEtMi44MmMtLjIyNi0uNjg3LS4zNTItMS40MTktLjM1Mi0yLjE4em0tNC4wMTMgMi4zNjVsLTQtMi40Nzl2LTEzLjI5NGw0IDIuNDc5djEzLjI5NHoiLz48L3N2Zz4="
                alt="Map icon"
              />
              <h3>Our Affiliates</h3>
            </a>
          </div>
        </div>
      </div>

      <NavPanel
        product={product}
        resources={resources}
        about={about}
        menu={menu}
        setToggle={setToggle}
      />
    </header>
  );
};

export default Header;
