import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/v1593455391/rs-godwin/nav/hybrid/standard-hybrid_su7gw9.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/v1595264664/rs-godwin/products/hybrid/standard/3_qssoco.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/v1595264664/rs-godwin/products/hybrid/standard/2_jbqzih.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/v1595264664/rs-godwin/products/hybrid/standard/1_pixgww.jpg"
  }
];

const StandardSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Hybrid Dump Body</h2>
        <h1 className="product-maintitle">Standard Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.HybridPhotos.StandardMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="Standard Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The Hybrid Series mixed composition dump body combines the
            durability of a AR 450 steel floor and hoist well, stainless steel
            front, rubber cushioned formed steel trapezoid longbeams, and
            lightweight low maintenance aluminum sides and tailgate. R/S-Godwin
            manufactures the only one of its kind in the industry today. This
            body lasts significantly longer than a comparably priced all
            aluminum body doing the same hauling and is light enough to
            similarly maximize your hauling capability.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.HybridSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>Various body lengths and side heights.</li>
          <li>1/4" (5454-H34) Aluminum floor.</li>
          <li>4" U-shaped (6061-T6) crossmembers.</li>
          <li>
            5" (6061-T6) I-Beam longbeams with shock absorbing rubber cushion
            for quiet ride.
          </li>
          <li>1/4" (5454-H34) Aluminum rear posts.</li>
          <li>
            Sides and floor connected with interlocking extrusions at all
            critical points to increase stability and durability.
          </li>
          <li>
            Lightweight 3/16" aluminum sides with vertical and horizontal side
            bracing, 8" heavy duty extruded aluminum top rail and 3/16" extruded
            aluminum lower rails.
          </li>
          <li>
            Air operated 3/16" aluminum tailgate with galvanized steel upward
            acting top and bottom hardware with full width 1-1/4" diameter pins.
          </li>
          <li>Recessed FMVSS108 L.E.D. lighting.</li>
          <li>Full width aluminum cab protector.</li>
          <li>Front mount telescopic hoists.</li>
          <li>Required DOT under ride protection.</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Heavier Deck And Sides</li>
            <li>Spreader Apron</li>
            <li>Shovel Holder</li>
            <li>Stone Shield</li>
            <li>Stoker Door/Chutes</li>
            <li>Lift Axles</li>
            <li>Tool Box</li>
            <li>Additional Lighting</li>
            <li>Bed Liners</li>
            <li>Air PTO Control</li>
            <li>Air Pump Controls</li>
          </ul>
          <ul>
            <li>Various Tarping System</li>
            <li>Offset Cabshield</li>
            <li>Aluminum Hydraulic Tank</li>
            <li>Aluminum Control Tower</li>
            <li>Sealed Tailgate</li>
            <li>Chrome Exhaust S-Pipe</li>
            <li>Various Side Heights</li>
            <li>Push Bumpers and Blocks</li>
            <li>Stop/Tail/Turn Signals</li>
            <li>Flap Light Modules</li>
          </ul>
        </div>
      </div>
      <ProductCta />;
    </section>
  );
};

export default StandardSeries;
