import React, { useState } from "react";

const Testimonials = () => {
  const [collapsed, setCollapsed] = useState({
    firstArticle: true,
    secondArticle: true,
    thirdArticle: true,
    fourthArticle: true
  });

  return (
    <section className="testimonials-container">
      <div className="testimonials-title-container">
        <h1
          className="testimonials-title main-title border-line"
          id="testimonials"
        >
          What Our Customers Had To Say
        </h1>
      </div>
      <article className="testimonials-story default-grid">
        <div className="testimonials-story__info">
          <h1 className="main-title">
            <span>Hilco</span>
          </h1>
          <div
            className={`${
              collapsed.fourthArticle ? "is-collapsed" : "is-open"
            }`}
          >
            <p className="main-info">
              "At Hilco, we strive to provide our customers with quality service
              and we expect the same from our vendors. We have a long-standing
              relationship with the team at R/S Godwin and know we can depend on
              them to provide us with a quality product and excellent customer
              service every time. In order to maintain the level of service our
              customers have come to expect from us, we must have people and
              products we can trust and depend on and we have found that with
              the R/S Godwin team."
            </p>
            <p className="main-author">Jeff Loudermilk, COO</p>
          </div>
          <button
            onClick={() => {
              setCollapsed({
                firstArticle: collapsed.firstArticle,
                secondArticle: collapsed.secondArticle,
                thirdArticle: collapsed.thirdArticle,
                fourthArticle: !collapsed.fourthArticle
              });
            }}
          >
            {collapsed.fourthArticle ? "Show More" : "Show Less"}
          </button>
        </div>
        <picture>
          <img
            sizes="(max-width: 1400px) 100vw, 1400px"
            srcSet="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_626/v1/rs-godwin/testimonials/hilco_rungmh.jpg 636w,
            https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_834/v1/rs-godwin/testimonials/hilco_rungmh.jpg 834w,
            https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_1081/v1/rs-godwin/testimonials/hilco_rungmh.jpg 1081w,
            "
            src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale/v1/rs-godwin/testimonials/hilco_rungmh.jpg"
            alt="Hilco truck"
            loading="lazy"
          />
        </picture>
      </article>
      <article className="testimonials-story default-grid">
        <div className="testimonials-story__info">
          <h1 className="main-title">
            <span>Limerick Township Public Works</span>
          </h1>
          <div
            className={`${collapsed.firstArticle ? "is-collapsed" : "is-open"}`}
          >
            <p className="main-info">
              "Here at Limerick Township, we have been working with Lancaster
              Truck Bodies and Jim Bruno for over 15 yrs. We have RS aluminum
              Godwin truck bodies on all seven of our F-550 dump trucks. The
              quality and superior strength of Godwin are unmatched, we have our
              bodies custom built with such items as: all lighting built into
              the bodies, custom toolboxes, and side steps, drop sides, cat
              walks. Workman ship of the bodies and the working relationship
              with Lancaster makes our job picking a great product very simple."
            </p>
            <p className="main-author">William R. Bradford</p>
          </div>
          <button
            onClick={() => {
              setCollapsed({
                firstArticle: !collapsed.firstArticle,
                secondArticle: collapsed.secondArticle,
                thirdArticle: collapsed.thirdArticle,
                fourthArticle: collapsed.fourthArticle
              });
            }}
          >
            {collapsed.firstArticle ? "Show More" : "Show Less"}
          </button>
        </div>
        <picture>
          <img
            sizes="(max-width: 1400px) 100vw, 1400px"
            srcSet="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_584/v1/rs-godwin/testimonials/lancaster-truck-bodies_l30heq.jpg 584w,
            https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_730/v1/rs-godwin/testimonials/lancaster-truck-bodies_l30heq.jpg 730w,
            https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_1053/v1/rs-godwin/testimonials/lancaster-truck-bodies_l30heq.jpg 1053w,
            "
            src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale/v1/rs-godwin/testimonials/lancaster-truck-bodies_l30heq.jpg"
            alt="Limerick Township Public Work Trucks"
            loading="lazy"
          />
        </picture>
      </article>
      <article className="testimonials-story default-grid">
        <div className="testimonials-story__info">
          <h1 className="main-title">
            <span>Roberts Transport</span>
          </h1>
          <div
            className={`${
              collapsed.secondArticle ? "is-collapsed" : "is-open"
            }`}
          >
            <p className="main-info">
              "The trip to R/S was intriguing. I've been in the dump truck
              business for twenty years and never imagined I would like it as
              much as I do. When the time came to buy a new Peterbilt, every
              good and bad experience I've ever had in a dump truck came to
              mind. Like what could have or should have been done differently
              when the truck was built to make it a better unit for the driver.
              With my first ordered truck being set up to my preference, the
              time came for setting up dump bodies. The dealership had a plan
              that was on track. After my normal 50 questions, my salesman said
              to take a trip to R/S. He said for me to go look at the truck and
              tell them where and what it is I wanted. Really? Yes. So I planned
              a quick trip to Kentucky. I arrived the night before to get an
              early start in the morning. We arrived to smiles at the front door
              with everyone already busy with their day. We explained who we
              were and who we needed to see and the ball started rolling. The
              next thing I knew, we were being escorted through the shop to my
              truck where the technician was going to handle the installation of
              my dump body. We discussed a few things. One of which was the
              placement of the console (too many hours sitting in a truck, I
              wanted it out of my way as much as possible)."
            </p>
            <p className="main-info">
              "Everything was set. After that we go find the dump body. We
              discussed tweaking a couple of things and it was set to be
              finished. Afterwards, I got the tour of what I hadn't seen up to
              that point. Have you ever been somewhere and stand back long
              enough to watch what was going on? Maybe it's a different work
              environment than what I see on a daily basis, but everyone that
              came by us spoke politely. Everyone you saw appeared to be busy
              completing their task. That gave me the feeling that these
              employees are building a product to be proud of. Their actions
              alone showed me that this is a quality built unit that someone
              took the time to do a job right. I know some may say you know that
              from just watching someone briefly. YES! The environment inside
              that shop screamed teamwork at every step. So yes , I'm proud to
              say I own two of the best products in the dump truck market
              PETERBILT and R&#38;S-GODWIN BODY."
            </p>
            <p className="main-author">Trent Roberts (Roberts Transport)</p>
            <picture>
              <img
                sizes="(max-width: 963px) 100vw, 963px"
                srcSet="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_544/v1/rs-godwin/testimonials/trent-roberts_zfbqvl.jpg 544w,
                https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_774/v1/rs-godwin/testimonials/trent-roberts_zfbqvl.jpg 774w,
            "
                src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale/v1/rs-godwin/testimonials/trent-roberts_zfbqvl.jpg"
                alt="Roberts Transport"
                loading="lazy"
              />
            </picture>
          </div>
          <button
            onClick={() => {
              setCollapsed({
                firstArticle: collapsed.firstArticle,
                secondArticle: !collapsed.secondArticle,
                thirdArticle: collapsed.thirdArticle,
                fourthArticle: collapsed.fourthArticle
              });
            }}
          >
            {collapsed.secondArticle ? "Show More" : "Show Less"}
          </button>
        </div>
      </article>
      <article className="testimonials-story default-grid">
        <div className="testimonials-story__info">
          <h1 className="main-title">
            <span>Ray Walker Trucking</span>
          </h1>
          <div
            className={`${collapsed.thirdArticle ? "is-collapsed" : "is-open"}`}
          >
            <p className="main-info">
              "We get paid by the ton. We look to R/S-Godwin for durable
              lightweight truck bodies so we can get the most bang for the buck.
              The Hybrid body gives us the best of both worlds. We save weight
              with aluminum sides and gate, get the durability of AR450 steel in
              the floor and end up with a low maintenance body that will give us
              years of trouble free service."
            </p>
            <p className="main-author">
              Michael Walker (Vice President of Ray Walker Trucking)
            </p>
          </div>
          <button
            onClick={() => {
              setCollapsed({
                firstArticle: collapsed.firstArticle,
                secondArticle: collapsed.secondArticle,
                thirdArticle: !collapsed.thirdArticle,
                fourthArticle: collapsed.fourthArticle
              });
            }}
          >
            {collapsed.thirdArticle ? "Show More" : "Show Less"}
          </button>
        </div>
        <picture>
          <img
            sizes="(max-width: 1400px) 100vw, 1400px"
            srcSet="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_569/v1/rs-godwin/testimonials/ray-walker-trucking_ap1bhb.jpg 569w,
            https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_807/v1/rs-godwin/testimonials/ray-walker-trucking_ap1bhb.jpg 807w,
            https://res.cloudinary.com/clee/image/upload/f_auto,c_scale,w_1045/v1/rs-godwin/testimonials/ray-walker-trucking_ap1bhb.jpg 1045w,
            "
            src="https://res.cloudinary.com/clee/image/upload/f_auto,c_scale/v1/rs-godwin/testimonials/ray-walker-trucking_ap1bhb.jpg"
            alt="Ray Walker Trucking Inc"
            loading="lazy"
          />
        </picture>
      </article>
    </section>
  );
};

export default Testimonials;
