import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/el-series/1_xuuh68.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/el-series/2_eedpv6.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/el-series/4_kninz5.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/el-series/5_cm2oyv.jpg"
  },
  {
    key: 5,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/el-series/3_w3meau.jpg"
  }
];

const ElSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Steel Dump Body</h2>
        <h1 className="product-maintitle">EL Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.SteelPhotos.ElSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="El Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The EL series steel elliptical body was designed to be lightweight
            yet super durable. It's elliptical build makes unloading faster and
            cleaner than conventional box style bodies. The EL also has a lower
            center of gravity than conventional bodies thus helping to improve
            overall truck operation. With an air operated highlift tailgate
            which also functions as a standard tailgate, this body should be at
            the top of every operators list.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.ElSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>
            Various body lengths from 13' to 22' and side heights from 42" to
            64" are standard
          </li>
          <li>26" side to floor radius for improved dumping</li>
          <li>
            Formed 1/4" trapezoid longsills with rubber cushion for quiet ride
          </li>
          <li>3/16" AR450 steel floor</li>
          <li>Light weight crossmemberless construction</li>
          <li>3/16" material shedding formed box top rails</li>
          <li>Full pin rear hinge</li>
          <li>Full length fenders</li>
          <li>10 gauge high tensile steel bulkhead</li>
          <li>Recessed FMVSS108 L.E.D. lighting</li>
          <li>Signals in rear posts</li>
          <li>Front mount telescopic hoists sized for body lengths</li>
          <li>Air operated tailgate latching system</li>
          <li>PPG Delfleet Evolution and Delfleet Essential</li>
          <li>Spreader chains</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>1/4” AR450 Floor</li>
            <li>10 Gauge High Tensile thru 1/4” AR450 Sides</li>
            <li>Body Side Graphics</li>
            <li>Horizontal Side Braces</li>
            <li>1/4” High Tensile Rear Post</li>
            <li>Asphalt Tapered Rear</li>
            <li>Aluminum Tailgate</li>
            <li>Sloped Tailgate</li>
            <li>Stoker Door</li>
            <li>Bolt or Weld On Pipe Apron Options</li>
            <li>Wood, Steel or Aluminum Boards</li>
            <li>Inverted Angle on Top Rail</li>
            <li>Aluminum Fender Overlays</li>
          </ul>
          <ul>
            <li>
              Air Highlift tailgate that also works as a conventional tailgate
              (works both ways)
            </li>
            <li>10 Gauge High Tensile thru 1/4” AR450 Tailgate</li>
            <li>10 Gauge High Tensile thru 1/4” AR450 Bulkhead</li>
            <li>Offset Bulkhead to Clear Exhaust</li>
            <li>Mesh or Asphalt Tarp Systems</li>
            <li>Aluminum Toolboxes</li>
            <li>Aluminum Hydraulic Tanks</li>
            <li>Chrome Exhaust S-Pipe</li>
            <li>Sealed Tailgate</li>
            <li>Inside Body Overlays</li>
            <li>Aluminum Control Tower</li>
            <li>Lift Axles</li>
            <li>Wing Latches</li>
          </ul>
        </div>
      </div>
      <ProductCta />
    </section>
  );
};

export default ElSeries;
