import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ag-series/1_x6dd2z.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ag-series/2_hi0mxt.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ag-series/3_ohveum.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ag-series/4_vvo8y5.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ag-series/5_c9svvv.jpg"
  }
];

const AgSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Aluminum Dump Body</h2>
        <h1 className="product-maintitle">AG Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.AlumPhotos.AgSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="AG Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            Leave it to the people at R/S to deliver one of the industry's most
            popular aluminum construction bodies. The AG Series provides the
            asphalt pavers a lighter aluminum alternative. Standard features
            include extensive use of extrusions at all critical points tying the
            understructure, floor walls and posts together resulting in
            unsurpassed structural integrity.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.AgSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>
            Various body lengths from 10’ to 22’ and side heights from 30” to
            46”.
          </li>
          <li>Corner angled 1/4” (5454) Aluminum floor.</li>
          <li>4” boxed extrusion (6061) crossmembers.</li>
          <li>
            5” I-Beam longbeams with shock absorbing rubber cushion for quiet
            ride.
          </li>
          <li>Durable 1/4” (5454) Aluminum formed box type full rear posts.</li>
          <li>
            Sides and floor connected with interlocking extrusions at all
            critical points to increase stability and durability.
          </li>
          <li>
            Lightweight 3/16” aluminum sides with vertical side braces, 8” heavy
            duty extruded aluminum top rail and 3/16” aluminum lower rails.
          </li>
          <li>
            Air operated 3/16” aluminum tailgate with galvanized steel upward
            acting top and bottom hardware.
          </li>
          <li>Recessed FMVSS108 L.E.D. lighting.</li>
          <li>Full width aluminum cab protector.</li>
          <li>
            Full width or offset bolt-on aluminum cab protector in 16” to 42”
            lengths.
          </li>
          <li>Front mount telescopic hoists sized for body lengths.</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Heavier Deck And Sides</li>
            <li>Spreader Apron</li>
            <li>Shovel Holder</li>
            <li>Stone Shield</li>
            <li>Stoker Door/Chutes</li>
            <li>Lift Axles</li>
            <li>Asphalt Tapered Rear</li>
            <li>Tool Box</li>
            <li>Additional Lighting</li>
            <li>Bed Liners</li>
            <li>Air PTO Control</li>
            <li>Air Pump Controls</li>
          </ul>
          <ul>
            <li>Horizontal Side Bracing</li>
            <li>Various Tarping Systems</li>
            <li>Offset Cabshield</li>
            <li>Two Way Gate (Standard And Side Swing)</li>
            <li>Aluminum Hydraulic Tank</li>
            <li>Wood Boards Or Inverted Angle On Top Rail</li>
            <li>Custom Aluminum Consoles</li>
            <li>Sealed Tailgate</li>
            <li>Chrome Exhaust S-Pipe</li>
            <li>Air Tailgate</li>
            <li>Optional Side Heights</li>
            <li>Overlay Wear Plate</li>
            <li>Steel And Aluminum Tool Boxes</li>
            <li>Stop/Tail/Turn Signals</li>
            <li>Extruded Sides (Smooth Side)</li>
          </ul>
        </div>
      </div>
      <ProductCta />;
    </section>
  );
};

export default AgSeries;
