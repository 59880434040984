import React, { lazy } from "react";
import Slider from "react-slick";
import TransformImage from "./lib/TransformImage";

const ProductCarousel = ({ photos }) => {
  const settings = {
    customPaging: function(i) {
      return (
        <span>
          <TransformImage
            imageUrl={photos[`${i}`].photo}
            imageSizes={[320, 480, 768, 1024, 1200, 1400]}
            maxSize="(max-width: 1400px) 100vw, 1400px"
            className="carousel__image"
            key={i}
          />
        </span>
      );
    },
    dots: true,
    arrows: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    loading: lazy,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      {photos.map((el, i) => {
        return (
          <picture key={i}>
            <TransformImage
              imageUrl={el.photo}
              imageSizes={[320, 480, 768, 1024, 1200, 1400]}
              maxSize="(max-width: 1400px) 100vw, 1400px"
              className="carousel__image"
            />
          </picture>
        );
      })}
    </Slider>
  );
};

export default ProductCarousel;
