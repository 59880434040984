import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/sc-series/1_e1feqq.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/sc-series/2_f4dydw.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/sc-series/3_us14g3.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/sc-series/4_wrifdo.jpg"
  }
];

const ScSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Steel Dump Body</h2>
        <h1 className="product-maintitle">SC Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.SteelPhotos.ScSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="SC Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            Designed for general contractors and municipal use, the SC series
            steel dump body is perfect for tough, small to medium size hauling
            jobs. It’s crossmemberless design allows for increased payloads due
            to its lighter weight, and its 12” floor to side radius makes for
            cleaner dumping.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>Various body lengths from 8' to 12'</li>
          <li>12" side to floor radius for improved dumping</li>
          <li>Formed 3/16" trapezoid longsills</li>
          <li>3/16 high tensile steel floor</li>
          <li>Crossmemberless construction</li>
          <li>Material shedding formed box top rails</li>
          <li>Double acting tailgate</li>
          <li>10 gauge extra high strength steel bulkhead</li>
          <li>10 gauge extra high strength sides and tailgate</li>
          <li>Recessed FMVSS108 L.E.D. lighting</li>
          <li>Signals in rear posts</li>
          <li>Under body hoists sized for body lengths</li>
          <li>Manual tailgate latching system</li>
          <li>PPG Delfleet Evolution and Delfleet Essential</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />;
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Side Heights from 18” to 48”</li>
            <li>Vertical and Horizontal Side Bracing</li>
            <li>Wood, Steel or Aluminum Boards</li>
            <li>Multiple Decking Material Options</li>
            <li>Sloped Tailgate</li>
            <li>Stoker Door</li>
            <li>Aluminum Tailgate</li>
            <li>Air Tailgate</li>
            <li>Sealed Tailgate</li>
            <li>Wing Latches on Tailgate</li>
            <li>Air High Lift Tailgate</li>
            <li>Window in Bulkhead</li>
            <li>Quick Release Tailgate Hardware</li>
          </ul>
          <ul>
            <li>10 Gauge High Tensile thru 1/4” AR450 Steel</li>
            <li>Aluminum Toolboxes</li>
            <li>Various Tarping Systems (Mesh or Asphalt)</li>
            <li>Aluminum Hydraulic Tanks</li>
            <li>Side Graphics</li>
            <li>Chrome Exhaust S-Pipe</li>
            <li>Inverted Angle Available for Top Rails</li>
            <li>Bolt, Weld or Pipe Apron Options</li>
            <li>Aluminum Control Tower</li>
            <li>Additional Lighting</li>
            <li>Various Cabshield Lengths</li>
          </ul>
        </div>
      </div>
      <ProductCta />;
    </section>
  );
};

export default ScSeries;
