import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ac-series/Mountain-International-coal-body-014_t1wedx.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ac-series/Mountain-International-coal-body-019_sztonq.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ac-series/Mountain-International-coal-body-017_bcrod3.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/aluminum/ac-series/Mountain-International-coal-body-016_zj3lg0.jpg"
  }
];

const AcSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Aluminum Dump Body</h2>
        <h1 className="product-maintitle">AC Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.AlumPhotos.AcSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="AC Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The AC series aluminum body is designed to maximize your hauling
            ability without using a trailer. This body was created to satisfy
            the hauling needs of the Appalachian coal industry though it has
            proven its worth in a wide range of hauling industries. The AC
            series body's sturdy extruded aluminum construction provides
            operators with a lightweight workhorse that can handle the load.
          </p>
          <div style={{ display: "flex" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>Various body lengths and side heights.</li>
          <li>1/4" (5454-H34) Aluminum floor.</li>
          <li>4" U-shaped (6061-T6) crossmembers.</li>
          <li>
            5" (6061-T6) I-Beam longbeams with shock absorbing rubber cushion
            for quiet ride.
          </li>
          <li>1/4" (5454-H34) Aluminum rear posts.</li>
          <li>
            Sides and floor connected with interlocking extrusions at all
            critical points to increase stability and durability.
          </li>
          <li>
            Lightweight 3/16" aluminum sides with vertical and horizontal side
            bracing, 8" heavy duty extruded aluminum top rail and 3/16" extruded
            aluminum lower rails.
          </li>
          <li>
            Air operated 3/16" aluminum tailgate with galvanized steel upward
            acting top and bottom hardware with full width 1-1/4" diameter pins.
          </li>
          <li>Recessed FMVSS108 L.E.D. lighting.</li>
          <li>Full width aluminum cab protector.</li>
          <li>Front mount telescopic hoists.</li>
          <li>Required DOT under ride protection.</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Heavier Deck And Sides</li>
            <li>Spreader Apron</li>
            <li>Shovel Holder</li>
            <li>Stone Shield</li>
            <li>Stoker Door/Chutes</li>
            <li>Lift Axles</li>
            <li>Tool Box</li>
            <li>Additional Lighting</li>
            <li>Bed Liners</li>
            <li>Air PTO Control</li>
            <li>Air Pump Controls</li>
          </ul>
          <ul>
            <li>Various Tarping System</li>
            <li>Offset Cabshield</li>
            <li>Aluminum Hydraulic Tank</li>
            <li>Aluminum Control Tower</li>
            <li>Sealed Tailgate</li>
            <li>Chrome Exhaust S-Pipe</li>
            <li>Various Side Heights</li>
            <li>Push Bumpers and Blocks</li>
            <li>Stop/Tail/Turn Signals</li>
            <li>Flap Light Modules</li>
          </ul>
        </div>
      </div>
      <ProductCta />;
    </section>
  );
};

export default AcSeries;
