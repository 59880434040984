import React from "react";
import CompanyMap from "../CompanyMap";
import ContactForms from "../ContactForms";

const ContactPage = () => {
  return (
    <>
      <CompanyMap />
      <ContactForms />
    </>
  );
};

export default ContactPage;
