import React from "react";
import { throttle } from "lodash";

const useScrollHeight = () => {
  const [scroll, setScroll] = React.useState(window.pageYOffset);

  React.useEffect(() => {
    const handleScrollY = throttle(() => {
      setScroll(window.pageYOffset);
    }, 300);
    window.addEventListener("scroll", handleScrollY);
    return () => window.removeEventListener("scroll", handleScrollY);
  }, []);
  return scroll;
};

export default useScrollHeight;
