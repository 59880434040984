import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/hybrid/double-wall/1_l8linc.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/hybrid/double-wall/2_hwrf0v.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/hybrid/double-wall/3_euf82r.jpg"
  },
  {
    key: 4,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/hybrid/double-wall/4_rvy08p.jpg"
  },
  {
    key: 5,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/hybrid/double-wall/5_gds353.jpg"
  },
  {
    key: 6,
    photo:
      "https://res.cloudinary.com/clee/image/upload/v1594741006/rs-godwin/products/hybrid/double-wall/6_kr5snl.jpg"
  }
];

const DoubleWallSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Hybrid Dump Body</h2>
        <h1 className="product-maintitle">Double Wall Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.HybridPhotos.DoubleWallMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="Double Walled Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The Hybrid Series mixed composition dump body combines the
            durability of a AR 450 steel floor and hoist well, stainless steel
            front, rubber cushioned formed steel trapezoid longbeams, and
            lightweight low maintenance aluminum sides and tailgate. R/S-Godwin
            manufactures the only one of its kind in the industry today. This
            body lasts significantly longer than a comparably priced all
            aluminum body doing the same hauling and is light enough to
            similarly maximize your hauling capability.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.HybridSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>
            Various body lengths from 10’ to 24’ and side heights from 24” to
            60”
          </li>
          <li>
            Abrasion resisting 3/16”AR450 steel floor with 12” radius formed in
            place for strength.
          </li>
          <li>Crossmemberless “western style” understructure.</li>
          <li>
            Load supporting formed steel trapezoid longbeams with shock
            absorbing rubber cushion in formed steel track for quiet ride.
          </li>
          <li>
            Stainless steel bulkhead with high tensile or AR450 hoist well for
            strength and durability.
          </li>
          <li>
            Durable Huck®bolt construction with ECK barrier at bolted
            connections to prevent electrolysis.
          </li>
          <li>
            Lightweight 3/16” aluminum sides with vertical side braces, 8” heavy
            duty extruded aluminum top rail and 3/16” aluminum lower rails.
          </li>
          <li>
            Air operated 3/16” aluminum tailgate with galvanized steel upward
            acting top and bottom hardware.
          </li>
          <li>1/4” formed aluminum full rear post.</li>
          <li>Recessed FMVSS108 L.E.D. lighting.</li>
          <li>
            Full width or offset bolt-on aluminum cab protector in various sizes
            for body.
          </li>
          <li>Front mount telescopic hoists sized for body length</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>
              Straight or sloped tailgate in high lift or conventional design.
            </li>
            <li>Asphalt taper on rear</li>
            <li>Bolt on spreader apron</li>
            <li>Coal door or chute in gate</li>
            <li>Horizontal side brace or smooth side design</li>
            <li>Inverted angle, wood or aluminum boards on top of sides</li>
          </ul>
          <ul>
            <li>Steel or aluminum front mount or frame mount hydraulic tank</li>
            <li>Multiple oval lights in back of rear post</li>
            <li>Additional marker lights on sides</li>
            <li>Mid rail turn signals</li>
            <li>Electric tarping system</li>
            <li>Other options available</li>
          </ul>
        </div>
      </div>
      <ProductCta />;
    </section>
  );
};

export default DoubleWallSeries;
