import React from "react";
import Main from "../../backend";
import TransformImage from "../../lib/TransformImage";

// Components
import Button from "../../Button";
import ProductCarousel from "../../ProductCarousel";
import ProductCta from "../../ProductCta";

const photos = [
  {
    key: 1,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/g-series/1_mxq5c0.jpg"
  },
  {
    key: 2,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/g-series/2_qkfh0s.jpg"
  },
  {
    key: 3,
    photo:
      "https://res.cloudinary.com/clee/image/upload/rs-godwin/products/steel/g-series/3_ejdeza.jpg"
  }
];

const GSeries = () => {
  return (
    <section id="product-section">
      <div className="product-title">
        <h2 className="product-subtitle">Steel Dump Body</h2>
        <h1 className="product-maintitle">G Series</h1>
      </div>
      <article className="product-grid">
        <TransformImage
          imageUrl={Main.SteelPhotos.GSeriesMain}
          imageSizes={[320, 480, 768, 1024, 1200, 1400]}
          maxSize="(max-width: 1400px) 100vw, 1400px"
          className="product-image"
          alt="G Series body"
        />
        <div className="product-info">
          <h1 className="main-title">Overview</h1>
          <p className="main-info">
            The G series steel body is a traditional box design that has proven
            its worth as a tough body with endless capabilities. With its strong
            continuous welded construction, sturdy stacked understructure and
            boxed bracing, the G series is up for any challenge. This body is
            one of our most popular and is highly customizable with a wide rage
            of options.
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              cta={"cta-button"}
              text={"Request a Quote"}
              link={"/contact-us"}
            />
            <Button
              cta={"cta-button"}
              text={"Literature"}
              pdf={Main.PDF.GSeries}
            />
          </div>
        </div>
      </article>
      <div className="product-features">
        <h1>Standard Features</h1>
        <ul>
          <li>Body lengths from 8’ with various side heights available</li>
          <li>Vertical and/or horizontal side bracing</li>
          <li>Box design, seamless top rail</li>
          <li>Easy access twin safety pins</li>
          <li>Sturdy 4” structural channel crossmembers</li>
          <li>
            7” structural channel longsills are standard on tandom axle
            applications and 5” I-Beam longsills are standard on single axle
            applications
          </li>
          <li>10 gauge high tensile steel bulkhead</li>
          <li>
            Vertical side bracing per side with 10 gauge high tensile sides and
            tailgate
          </li>
          <li>Recessed FMVSS108 L.E.D. lighting</li>
          <li>Signals in rear posts</li>
          <li>
            Front mount telescopic underbody hoists sized for body lengths
          </li>
          <li>Continuous welded construction throughout</li>
          <li>PPG Delfleet Evolution and Delfleet Essential</li>
        </ul>
      </div>
      <ProductCarousel photos={photos} />
      <div className="product-options">
        <h1>Optional Features</h1>
        <div className="product-options-split">
          <ul>
            <li>Varilous Side Heights</li>
            <li>Horizontal Side Bracing</li>
            <li>Wood, Steel or Aluminum Boards</li>
            <li>Tubing Top Rail</li>
            <li>1/8” AR450 or 1/4” AR450 Deck Options</li>
            <li>Sloped Tailgate</li>
            <li>Asphalt Tapered Rear</li>
            <li>Stoker Doors</li>
            <li>Aluminum Tailgate</li>
            <li>Special X-Member Spacing</li>
            <li>Sealed Tailgate</li>
            <li>Wing Latches on Tailgate</li>
            <li>Air High Lift Tailgate</li>
            <li>Offset Bulkhead</li>
          </ul>
          <ul>
            <li>10 Gauge High Tensile through 1/4” AR450 Steel</li>
            <li>Overlays for Sides, Deck, Tailgate & Bulkhead</li>
            <li>Aluminum or Steel Toolboxes</li>
            <li>Various Tarp System (Mesh or Asphalt)</li>
            <li>Aluminum Hydraulic Tanks (side or front)</li>
            <li>Oak Cushion Floor</li>
            <li>Body Liners</li>
            <li>Inverted Angle Available for Top Rails</li>
            <li>Bolt, Weld or Pipe Apron Options</li>
            <li>Aluminum Control Tower</li>
            <li>Lift Axles</li>
            <li>Additional Lighting</li>
          </ul>
        </div>
      </div>
      <ProductCta />
    </section>
  );
};

export default GSeries;
