import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ViewportProvider, useViewport } from "./lib/viewportContext";
import { CSSTransition } from "react-transition-group";
import "./App.css";

// Hooks
import ScrollToTop from "./lib/ScrollToTop";

// Components
import Header from "./Header";
import CallToAction from "./CallToAction";
import Footer from "./Footer";

// Pages
import HomePage from "./pages/HomePage";
import CompanyPage from "./pages/CompanyPage";
import PaintProcessPage from "./pages/PaintProcessPage";
import ContactPage from "./pages/ContactPage";
import MaterialsPage from "./pages/MaterialPage";
import LiteraturePage from "./pages/LiteraturePage";

// Products
import CsSeries from "./products/steel/CsSeries";
import CSeries from "./products/steel/CSeries";
import GSeries from "./products/steel/GSeries";
import ElSeries from "./products/steel/ElSeries";
import ScSeries from "./products/steel/ScSeries";
import AcSeries from "./products/aluminum/AcSeries";
import AgSeries from "./products/aluminum/AgSeries";
import AuSeries from "./products/aluminum/AuSeries";
import DoubleWallSeries from "./products/hybrid/DoubleWallSeries";
import StandardSeries from "./products/hybrid/StandardSeries";

const App = () => {
  const useSize = useViewport();

  const routes = [
    { path: "/", name: "Home", Component: HomePage },
    { path: "/our-company", name: "Our Company", Component: CompanyPage },
    {
      path: "/paint-process",
      name: "Paint Process",
      Component: PaintProcessPage,
    },
    { path: "/contact-us", name: "Contact Us", Component: ContactPage },
    {
      path: "/materials",
      name: "Materials",
      Component: MaterialsPage,
    },
    {
      path: "/literature",
      name: "Literature",
      Component: LiteraturePage,
    },
    {
      path: "/products/steel-bodies/cs-series",
      name: "CS Series",
      Component: CsSeries,
    },
    {
      path: "/products/steel-bodies/c-series",
      name: "C Series",
      Component: CSeries,
    },
    {
      path: "/products/steel-bodies/g-series",
      name: "G Series",
      Component: GSeries,
    },
    {
      path: "/products/steel-bodies/el-series",
      name: "El Series",
      Component: ElSeries,
    },
    {
      path: "/products/steel-bodies/sc-series",
      name: "Sc Series",
      Component: ScSeries,
    },
    {
      path: "/products/aluminum-bodies/ac-series",
      name: "AC Series",
      Component: AcSeries,
    },
    {
      path: "/products/aluminum-bodies/ag-series",
      name: "AG Series",
      Component: AgSeries,
    },
    {
      path: "/products/aluminum-bodies/au-series",
      name: "AU Series",
      Component: AuSeries,
    },
    {
      path: "/products/hybrid-bodies/doublewall-series",
      name: "Double Wall Series",
      Component: DoubleWallSeries,
    },
    {
      path: "/products/hybrid-bodies/standard-series",
      name: "Standard Series",
      Component: StandardSeries,
    },
  ];

  return (
    <Router>
      <ViewportProvider value={useSize}>
        <ScrollToTop />
        <Header />

        {routes.map(({ path, name, Component }) => {
          return (
            <Route key={name} path={path} exact>
              {({ match }) => (
                <CSSTransition
                  in={match !== null}
                  timeout={1200}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page">
                    <Component />
                  </div>
                </CSSTransition>
              )}
            </Route>
          );
        })}

        <CallToAction />
        <Footer />
      </ViewportProvider>
    </Router>
  );
};

export default App;
