import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";

const CompanyMap = () => {
  return (
    <div style={{ height: "500px", width: "100%" }}>
      <Map
        style={{
          height: "100%",
          zIndex: "1"
        }}
        center={[37.5960097, -82.6846896]}
        zoom={9}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        />
        <Marker position={[37.5960097, -82.6846896]}>
          <Popup>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/ewVn42Y7QvnYmXZ56"
            >
              <span style={{ display: "block", textAlign: "center" }}>
                RS Godwin
              </span>
              5168 US-23, Ivel, KY 41642
            </a>
          </Popup>
        </Marker>
      </Map>
    </div>
  );
};

export default CompanyMap;
