import React from "react";
import { Link } from "react-router-dom";

const ProductCta = () => {
  return (
    <div className="product-cta">
      <div className="product-cta-title">
        <h1 className="main-title border-line">Interested In More?</h1>
      </div>
      <div className="product-cta-grid">
        <div className="product-cta-box">
          <Link to="/contact-us">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcuNSAyYy4yNzYgMCAuNS4yMjQuNS41djE5YzAgLjI3Ni0uMjI0LjUtLjUuNWgtMTFjLS4yNzYgMC0uNS0uMjI0LS41LS41di0xOWMwLS4yNzYuMjI0LS41LjUtLjVoMTF6bTIuNSAwYzAtMS4xMDQtLjg5Ni0yLTItMmgtMTJjLTEuMTA0IDAtMiAuODk2LTIgMnYyMGMwIDEuMTA0Ljg5NiAyIDIgMmgxMmMxLjEwNCAwIDItLjg5NiAyLTJ2LTIwem0tOS41IDFoM2MuMjc2IDAgLjUuMjI0LjUuNTAxIDAgLjI3NS0uMjI0LjQ5OS0uNS40OTloLTNjLS4yNzUgMC0uNS0uMjI0LS41LS40OTkgMC0uMjc3LjIyNS0uNTAxLjUtLjUwMXptMS41IDE4Yy0uNTUzIDAtMS0uNDQ4LTEtMXMuNDQ3LTEgMS0xYy41NTIgMCAuOTk5LjQ0OC45OTkgMXMtLjQ0NyAxLS45OTkgMXptNS0zaC0xMHYtMTNoMTB2MTN6Ii8+PC9zdmc+"
              alt="Call for a quote"
            />
            <h1>Request A Quote</h1>
          </Link>
        </div>
        <div className="product-cta-box">
          <Link to="/paint-process">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNCAxOWgtMXYtMi4yYy0xLjg1MyA0LjIzNy02LjA4MyA3LjItMTEgNy4yLTYuNjIzIDAtMTItNS4zNzctMTItMTJoMWMwIDYuMDcxIDQuOTI5IDExIDExIDExIDQuNjYgMCA4LjY0Ny0yLjkwNCAxMC4yNDktN2gtMi4yNDl2LTFoNHY0em0tMTEuMDM2IDBoLTEuODg2Yy0uMzQtLjk1Ny0uNDM3LTEuNTcxLTEuMTc3LTEuODc4aC0uMDAxYy0uNzQzLS4zMDgtMS4yNTEuMDYxLTIuMTYyLjQ5NGwtMS4zMzMtMS4zMzNjLjQyNy0uODk5LjgwNC0xLjQxNS40OTQtMi4xNjMtLjMwOC0uNzQtLjkyNi0uODM5LTEuODc4LTEuMTc3di0xLjg4NmMuOTU0LS4zMzkgMS41Ny0uNDM3IDEuODc4LTEuMTc4LjMwOC0uNzQzLS4wNi0xLjI0OC0uNDk0LTIuMTYybDEuMzMzLTEuMzMzYy45MTguNDM2IDEuNDIxLjgwMSAyLjE2Mi40OTRsLjAwMS0uMDAxYy43NC0uMzA3LjgzOC0uOTI0IDEuMTc3LTEuODc3aDEuODg2Yy4zNC45NTguNDM3IDEuNTcgMS4xNzcgMS44NzdsLjAwMS4wMDFjLjc0My4zMDggMS4yNTItLjA2MiAyLjE2Mi0uNDk0bDEuMzMzIDEuMzMzYy0uNDM1LjkxNy0uODAxIDEuNDIxLS40OTQgMi4xNjF2LjAwMWMuMzA3LjczOS45MTUuODM1IDEuODc4IDEuMTc4djEuODg2Yy0uOTUzLjMzOC0xLjU3MS40MzctMS44NzggMS4xNzgtLjMwOC43NDMuMDYgMS4yNDkuNDk0IDIuMTYybC0xLjMzMyAxLjMzM2MtLjkyLS40MzgtMS40Mi0uODAyLTIuMTU3LS40OTYtLjc0Ni4zMS0uODQ0LjkyNi0xLjE4MyAxLjg4em0tLjk0My00LjY2N2MtMS4yODkgMC0yLjMzMy0xLjA0NC0yLjMzMy0yLjMzMyAwLTEuMjg5IDEuMDQ0LTIuMzM0IDIuMzMzLTIuMzM0IDEuMjg5IDAgMi4zMzMgMS4wNDUgMi4zMzMgMi4zMzQgMCAxLjI4OS0xLjA0NCAyLjMzMy0yLjMzMyAyLjMzM3ptLTguMDIxLTUuMzMzaC00di00aDF2Mi4yYzEuODUzLTQuMjM3IDYuMDgzLTcuMiAxMS03LjIgNi42MjMgMCAxMiA1LjM3NyAxMiAxMmgtMWMwLTYuMDcxLTQuOTI5LTExLTExLTExLTQuNjYgMC04LjY0NyAyLjkwNC0xMC4yNDkgN2gyLjI0OXYxeiIvPjwvc3ZnPg=="
              alt="Painting process"
            />
            <h1>Our Painting Process</h1>
          </Link>
        </div>
        <div className="product-cta-box">
          <Link to="/materials">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDZoMTFsMS0zaDd2MWgtNWwtMyAxMGgtOWwtMi04em0zLjk5NSAyaC0uOTk1bDEuMDM2IDRoMS4wNTdsLTEuMDk4LTR6bTQuMTg2IDBoLS45OTVsMS4wMzYgNGgxLjA1N2wtMS4wOTgtNHptLTIuMDkzIDBoLS45OTVsMS4wMzYgNGgxLjA1N2wtMS4wOTgtNHptLjQ4My02Ljc5MWMuMTk4LS4xMzUuNDQzLS4yMDkuNjkyLS4yMDkuMzcyIDAgLjc1NC4xNjcgMSAuNTUzLjg1MSAxLjMzNyAyLjIwNyAzLjQ0NyAyLjIwNyAzLjQ0N2gtOS40N3MzLjcwNi0yLjUyNCA1LjU3MS0zLjc5MXptNC40MjkgMTcuNzkxdi4wMjZjLS4wMTQgMi4xOTYtMS44MDEgMy45NzQtNCAzLjk3NC0yLjIwOCAwLTQtMS43OTItNC00IDAtLjcyOS4xOTUtMS40MTEuNTM2LTJoLTEuNTM2di0yaDkuOTJsMy4wOC0xMGg0LjI3OWMuNDMxIDAgLjgxMy4yNzUuOTQ5LjY4NGwxLjc3MiA1LjMxNiAxLjMzNy42NjhjLjQwNi4yMDQuNjYzLjYxOS42NjMgMS4wNzN2NC4yNTljMCAuNTUzLS40NDcgMS0xIDFoLTEuMTI2Yy4wODIuMzE5LjEyNi42NTUuMTI2IDEgMCAyLjIwOC0xLjc5MiA0LTQgNHMtNC0xLjc5Mi00LTRoLTN6bS00LTEuNWMuODI4IDAgMS41LjY3MiAxLjUgMS41cy0uNjcyIDEuNS0xLjUgMS41LTEuNS0uNjcyLTEuNS0xLjUuNjcyLTEuNSAxLjUtMS41em0xMSAwYy44MjggMCAxLjUuNjcyIDEuNSAxLjVzLS42NzIgMS41LTEuNSAxLjUtMS41LS42NzItMS41LTEuNS42NzItMS41IDEuNS0xLjV6bS45OS0xMC41aC0yLjk5djNoMy44ODlsLS44OTktM3oiLz48L3N2Zz4="
              alt="Dump truck material"
            />
            <h1>Materials We Use</h1>
          </Link>
        </div>
        <div className="product-cta-box">
          <Link to="/our-company">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xLjk4MSA4LjQ0NGgyMC4wMzhjLjM5OCAwIC43NDcuMjY0Ljg1Ni42NDhsMS4xMDUgMy45MDQuMDIuMTM5YzAgLjIwOS0uMTI3LjQwMi0uMzMuNDhsLS4wMDEuMDAxYy0uMjQuMDkyLS41MTEtLjAwNS0uNjM1LS4yMzFsLTEuMTQ0LTIuMDcxLS4zMjggNy45NjdjLS4wMTcuNDAzLS4zNDcuNzE5LS43NDkuNzE5aC0uMDAxYy0uMzkzIDAtLjcxNi0uMzA2LS43NDYtLjY5OC0uMDY4LS44NjUtLjI0OS0yLjkzMy0uMzA0LTMuNzUyLS4wMjItLjM0LS4yNzEtLjU0LS41NDEtLjU0LS4yNDIgMC0uNTE0LjItLjUzNy41NC0uMDU1LjgxOS0uMjM2IDIuODg3LS4zMDQgMy43NTItLjAzLjM5Mi0uMzUyLjY5OC0uNzQ2LjY5OGgtLjAwMWMtLjQwMiAwLS43MzItLjMxNi0uNzQ5LS43MTktLjA4Ni0yLjA4LS40MzUtOC43MzYtLjQzNS04LjczNmgtMS42NjlzLS4zNDkgNi42NTYtLjQzNSA4LjczNmMtLjAxNy40MDItLjM0Ny43MTktLjc0OS43MTloLS4wMDFjLS4zOTQgMC0uNzE2LS4zMDYtLjc0Ni0uNjk4LS4wNjgtLjg2NS0uMjQ5LTIuOTMzLS4zMDQtMy43NTItLjAyMy0uMzQtLjI5NS0uNTQtLjUzNy0uNTRoLS4wMDRjLS4yNDIgMC0uNTE1LjItLjUzNy41NC0uMDU1LjgxOS0uMjM2IDIuODg3LS4zMDQgMy43NTItLjAzLjM5Mi0uMzUzLjY5OC0uNzQ2LjY5OGgtLjAwMWMtLjQwMiAwLS43MzItLjMxNi0uNzQ5LS43MTktLjA4Ni0yLjA4LS40MzUtOC43MzYtLjQzNS04LjczNmgtMS42ODFzLS4zNDkgNi42NTYtLjQzNSA4LjczNmMtLjAxNy40MDMtLjM0Ny43MTktLjc0OS43MTloLS4wMDFjLS4zOTQgMC0uNzE2LS4zMDYtLjc0Ni0uNjk4LS4wNjgtLjg2NS0uMjQ5LTIuOTMzLS4zMDQtMy43NTItLjAyMy0uMzQtLjI5NS0uNTQtLjUzNy0uNTQtLjI3IDAtLjUxOS4yLS41NDEuNTQtLjA1NS44MTktLjIzNiAyLjg4Ny0uMzA0IDMuNzUyLS4wMy4zOTItLjM1My42OTgtLjc0Ni42OThoLS4wMDFjLS40MDIgMC0uNzMyLS4zMTYtLjc0OS0uNzE5bC0uMzI4LTcuOTY3LTEuMTQ0IDIuMDcxYy0uMTI0LjIyNi0uMzk1LjMyMy0uNjM1LjIzMWwtLjAwMS0uMDAxYy0uMjAzLS4wNzgtLjMzLS4yNzEtLjMzLS40OGwuMDItLjEzOSAxLjEwNS0zLjkwNGMuMTA5LS4zODQuNDU4LS42NDguODU2LS42NDh6bTMuMDE5LTQuNDQ0YzEuMTA0IDAgMiAuODk2IDIgMnMtLjg5NiAyLTIgMi0yLS44OTYtMi0yIC44OTYtMiAyLTJ6bTE0IDBjLTEuMTA0IDAtMiAuODk2LTIgMnMuODk2IDIgMiAyIDItLjg5NiAyLTItLjg5Ni0yLTItMnptLTYuOTk0IDBjMS4xMDQgMCAyIC44OTYgMiAycy0uODk2IDItMiAyLTItLjg5Ni0yLTIgLjg5Ni0yIDItMnoiLz48L3N2Zz4="
              alt="Family icon"
            />
            <h1>All About RS Godwin</h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCta;
